/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import AppBarMUI from '@material-ui/core/AppBar';
import BackIcon from '@material-ui/icons/ArrowBack';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import BlockIcon from '@material-ui/icons/ViewWeek';
import DashboardIcon from '@material-ui/icons/Equalizer';
import RecordsIcon from '@material-ui/icons/FolderOutlined';
import ListIcon from '@material-ui/icons/List';
import Location from '@material-ui/icons/MyLocation';
import Menu from '@material-ui/core/Menu';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import OfflineIcon from '@material-ui/icons/CloudOffOutlined';
import Divider from '@material-ui/core/Divider';
import Can from '../Can';

const styles = (theme) => ({
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  bottomnav: {
    color: 'inherit',
    backgroundColor: 'inherit',
  },
  navAction: {
    color: 'inherit',
    backgroundColor: 'inherit',
    paddingLeft: '0px',
    paddingRight: '0px',
    minWidth: '65px',
  },
  toolbar: {
    color: 'inherit',
    backgroundColor: 'inherit',
    paddingLeft: '0px',
    paddingRight: '0px',
    justifyContent: 'center',

  },

  icon: {
    color: 'inherit',
  },
  button: {
    // marginRight: theme.spacing(3),

  },
  buttonMore: {
    // marginRight: theme.spacing(1),

  },
  selected: {

    color: `${theme.palette.secondary.main} !important`, // "white",

  },
  disabled: {
    color: '#c2c2c2 !important',
  },

});
class AppBarMobile extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this); // i think you are missing this

    this.state = {
      // menuOpen: false,
      // value:this.props.pathname,
      anchorEl: null,
    };
  }

  onOpenMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleLocalLogout = () => {
    const { handleLogout } = this.props;
    handleLogout();
    this.handleClose();
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  }

  goBack() {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      classes,
      history,
      userDetail,
      isAuthenticated,
      online,
      location: { pathname },
      getFromGlobalState,
    } = this.props;
    const farm = getFromGlobalState('farm');
    const siteList = getFromGlobalState('siteList')
    const confirmed = siteList.length === 1 && siteList[0].isNewAccount
    const isEntity = !!(farm && (farm[0] && farm[0].entityType && farm[0].entityType === 'entity'));

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <AppBarMUI color="primary" position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {isAuthenticated
      && (
      <BottomNavigation
        // eslint-disable-next-line max-len
       // classes={{ selected: classes.selected, root: classes.bottomnav, disabled: classes.disabled }}
        classes={{ root: classes.bottomnav }}
        value={pathname}
      >
        {!online && (
        <OfflineIcon
          style={{
            position: 'fixed', bottom: 'auto', zIndex: '100', right: '3px', top: '0px', left: 'auto',
          }}
          color="error"
        />
        )}
        <BottomNavigationAction
          disabled={(history.location.pathname === '/')}
          onClick={this.goBack}
          label="Back"
          classes={{
            // selected: classes.selected,
            root: classes.navAction,
            // disabled: classes.disabled,
          }}
          value="/back"
          icon={<BackIcon />}
        />

        {!isEntity && (
        <BottomNavigationAction
          classes={{ selected: classes.selected, root: classes.navAction }}
          label="Blocks"
          value="/"
          component={Link}
          to="/"
          icon={<BlockIcon />}
        />
        )}

        {!isEntity && (
        <BottomNavigationAction
          classes={{ selected: classes.selected, root: classes.navAction }}
          label="Records"
          value="/records"
          disabled={isEntity}
          component={Link}
          to="/records"
          icon={<RecordsIcon />}
        />
        )}

        <BottomNavigationAction
          classes={{ selected: classes.selected, root: classes.navAction }}
          label="Locate"
          value="/location"
          component={Link}
          to="/location"
          icon={<Location />}
        />

        {!isEntity && (
        <Can
          role={userDetail && userDetail.rights && userDetail.rights.viewDashboard ? 'viewDashboard' : 'noAccess'}
          perform="viewDashboard"
          isAdministrator={userDetail && userDetail.rights && userDetail.rights.administrator}
          yes={() => (
            <BottomNavigationAction
              classes={{ selected: classes.selected, root: classes.navAction }}
              label="Stats"
              value="/dashboard"
              disabled={isEntity}
              component={Link}
              to="/dashboard"
              icon={<DashboardIcon />}
            />
          )}
          no={() => null}
        />
        )}

       {!isEntity && (
        <BottomNavigationAction
          classes={{ selected: classes.selected, root: classes.navAction }}
          label="Inventory"
          value="/inventory"
          disabled={isEntity}
          component={Link}
          to="/inventory"
          icon={<ListIcon />}
        />
        )}

        <BottomNavigationAction
          classes={{ selected: classes.selected, root: classes.navAction }}
          label="More"
          value="more"
          onClick={this.onOpenMenu}
          icon={<MoreIcon />}
        />

      </BottomNavigation>
      )}

          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                // maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}
          >
            <MenuItem component={Link} to="/version" onClick={this.handleClose}>
              About
            </MenuItem>

            {isAuthenticated && (
              <div>
                <Can
                  role={userDetail && userDetail.rights && userDetail.rights.changeSettings ? 'changeSettings' : 'noAccess'}
                  perform="openDocumentsPage"
                  yes={() => (
                    <MenuItem component={Link} to="/documents" onClick={this.handleClose}>
                      Documents
                    </MenuItem>
                  )}
                />
                <Can
                  role={userDetail && userDetail.rights && userDetail.rights.mangeInventory ? 'mangeInventory' : 'noAccess'}
                  perform="openInventoryPage"
                  yes={() => (
                    <MenuItem disabled component={Link} to="/inventory" onClick={this.handleClose}>
                      Inventory
                    </MenuItem>
                  )}
                />
                <MenuItem component={Link} to="/messages" onClick={this.handleClose}>
                  Messages
                </MenuItem>
              </div>
            ) }
            {isAuthenticated ? (
              <>
                {' '}
                <Divider />
                {' '}
                <Can
                  role={userDetail && userDetail.rights && userDetail.rights.changeSettings ? 'changeSettings' : 'noAccess'}
                  perform="openSettingsPage"
                  yes={() => (
                    <MenuItem component={Link} to="/settings" onClick={this.handleClose}>
                      Settings
                    </MenuItem>
                  )}
                />
                <MenuItem color="inherit" onClick={this.handleLocalLogout} disabled={!online}>
                  Logout
                </MenuItem>
              </>
            )

              : (
                <div>
                  <MenuItem disabled component={Link} to="/signup" onClick={this.handleClose}>
                    Sign Up
                  </MenuItem>
                  <MenuItem component={Link} to="/login" onClick={this.handleClose}>
                    Login
                  </MenuItem>
                </div>
              )}
            {isAuthenticated && userDetail && userDetail.rights && userDetail.rights.ldadmin
            && (
            <>
              <Divider />
              <MenuItem component={Link} to="/admin" onClick={this.handleClose}>
                Admin
              </MenuItem>
            </>
            )}

          </Menu>

        </Toolbar>
      </AppBarMUI>

    );
  }
}

AppBarMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  userDetail: PropTypes.object.isRequired,
  // handleDrawerToggle: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  // reload: PropTypes.func.isRequired,
  online: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.any.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.any.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

export default withStyles(styles, { withTheme: true })(AppBarMobile);
