import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ExpandLess from '@material-ui/icons/ExpandLess';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import GenDialog from '../../components/GenDialog';
import { validate, validateForm } from '../../libs/validate';
import { numberOfPlants } from '../../libs/analytics';
import { getNameFromKey2 } from '../../libs/genLib';
import Switch from '@material-ui/core/Switch';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(2),
  },
  formControlC: {
    minWidth: 100,
    margin: theme.spacing(1),
    // marginTop: theme.spacing(2),
  },
  cluster: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  helperText: {
    color: 'red',
  },

});

class PlantingD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isloading: false,
      cultivarType: null,
      cultivarName: null,
      rootStockType: null,
      rootStockName: null,
      isNew: this.props.isNew,
      planting: this.props.planting,
      openedCluster: '',
      selectedCluster: null,
      selectedClusterItem: null,
      errors: { count: 0 },
      disabled:false,
      varieties: [],
      changed: false,
      requiredFields: [
        {
          name: 'size', min: 0, max: 10000000000000, type: 'number',
        },
        { name: 'crop', min: 1, max: 100 },
        { name: 'name', min: 1, max: 100 },
        { name: 'variety', min: 1, max: 300 },
        {
          name: 'plantSpacing', min: 1, max: 40, condition: { name: 'totalPlantsOverride', value: false },
        },
        {
          name: 'plantDensity', min: 1, max: 40, condition: { name: 'totalPlantsOverride', value: false },
        },
        { name: 'totalPlants', min: 1, max: 40 },
        { name: 'date', min: 1, max: 40 }
      ],

    };
  }

  /* if (this.props.isNew){
      let today = new Date();
      let now = today.getHours() + ":" + today.getMinutes();
      this.setState({record: {date: today.toISOString().substr(0,10), time: now}});
    }
    else {
      this.setState({record: this.props.record});
    }
  } */

  componentDidMount() {
  //  const availableSize = this.props.block ? parseFloat(this.props.block.size) - this.plantedSize(this.props.block): 0;
    if (this.props.isNew) {
      const today = new Date();
      // this.setState({planting: {}});
      this.setState({ planting: { totalPlantsOverride: false, date: today.toISOString().substr(0, 10) } });
    } else {
      const { planting } = this.state;
      const { crops } = this.props.metaData;
      if (planting.variety && planting.crop) {
        const selectedVariety = crops.find((c) => c.sk === planting.crop) && crops.find((c) => c.sk === planting.crop).varieties.find((v) => v.sk === planting.variety);
        if (selectedVariety) {
          this.setState({
            cultivarType: selectedVariety.cultivarType,
            cultivarName: selectedVariety.cultivar,
            rootStockType: selectedVariety.rootStockType,
            rootStockName: selectedVariety.rootStock,
          });
        }
      }
      // let { planting } = this.props;
      // if (!planting.size){
      //   planting.size = availableSize;
      //   this.setState({availableSize, planting: planting });
      // }
    }
    // const requiredFields =[...this.state.requiredFields];
    // const idx = requiredFields.findIndex((p) => p.name === 'size');
    // requiredFields[idx] = { name:'size', min: 0, max: availableSize, type: 'number' };
    // this.setState({requiredFields});
  }

  validateField(field, requiredFields) {
    const error = validate(field, this.state.planting, requiredFields);
    const errors = { ...this.state.errors };
    errors[field] = error.msg;
    this.setState({ errors });
  }

  handleBlur = (event) => this.validateField(event.target.id, this.state.requiredFields)

plantedSize =(block) => {
  if (block && block.plantings && block.plantings.length > 0) {
    return block.plantings.reduce((prev, cur) => prev + parseFloat(cur.size), 0);
  }

  return 0;
}

handleClusterItemChange = (event) => {
  this.setState({ selectedClusterItem: event.target.value });
}

handleAddCluster = (event) => {
  const planting = { ...this.state.planting };
  const { selectedCluster, selectedClusterItem } = this.state;
  if (!planting.clusters) {
    planting.clusters = [];
  }
  let cluster = planting.clusters.find((c) => c.sk === selectedCluster.sk);
  if (!cluster) {
    planting.clusters.push({ sk: selectedCluster.sk });
    cluster = planting.clusters.find((c) => c.sk === selectedCluster.sk);
  }
  if (!cluster.items) {
    cluster.items = [];
  }
  const clusterItem = cluster.items.find((i) => i === selectedClusterItem);
  if (!clusterItem) {
    cluster.items.push(selectedClusterItem);
  }
  this.setState({ planting, changed: true, openedCluster: cluster.sk });
}

handleClusterChange = (event) => {
  // const { selectedCluster } = this.state;
  const selectedCluster = this.props.getFromGlobalState('clusters').find((c) => c.sk === event.target.value);
  // selectedCluster = event.target.value;
  this.setState({ selectedCluster });
}

  handleChange = (event) => {
    const planting = { ...this.state.planting };
    planting[event.target.id] = event.target.value;
    this.setState({ planting, changed: true });
  }

  handleSwitchChange = (event) => {
    const planting = { ...this.state.planting };
    planting['disabled'] = !planting.disabled;
    console.log(planting.disabled);
    this.setState({ planting, changed: true });
  }

  handleLocalChange = (event) => {
    // const { localState } = this.state;
    // localState[event.target.id] = event.target.value;
    this.setState({ [event.target.id]: event.target.value, changed: true });
  }

  handleLocalChangeSetVariety = (event) => {
    const { cultivarType, cultivarName, rootStockType } = this.state;
    const rootStockName = event.target.value;
    const { crops } = this.props.metaData;
    const planting = { ...this.state.planting };
    const variety = crops.find((c) => c.sk === planting.crop).varieties.find((v) => v.cultivarType === cultivarType && v.cultivar === cultivarName && v.rootStockType === rootStockType && v.rootStock === rootStockName);
    planting.variety = variety.sk;
    this.setState({ rootStockName, planting, changed: true });
  }

  handleClusterOpen = (event) => {
    const { openedCluster } = this.state;
    if (openedCluster === event) {
      this.setState({ openedCluster: '' });
    } else {
      this.setState({ openedCluster: event });
    }
  }

  handleDeleteCluster = (event) => {
    const planting = { ...this.state.planting };
    if (!planting.clusters) {
      return;
    }
    const idx = planting.clusters.findIndex((c) => c.sk === event);
    if (idx !== -1) {
      planting.clusters.splice(idx, 1);
    }
    this.setState({ planting, changed: true });
  }

  handleDeleteClusterItem = (c, item) => {
    const planting = { ...this.state.planting };
    if (!planting.clusters) {
      return;
    }
    const cluster = planting.clusters.find((b) => b.sk === c);
    if (!cluster) {
      return;
    }
    const idx = cluster.items.find((i) => i === item);
    if (idx !== -1) {
      cluster.items.splice(idx, 1);
    }
    this.setState({ planting, changed: true });
  }

  generateRows(crops) {
    const rows = [];
    if (!crops) {
      return rows;
    }
    const { getFromGlobalState } = this.props;
    const cropTypes = getFromGlobalState('cropTypesSetting');
    if (!cropTypes && cropTypes.length === 0) {
      return rows;
    }
    crops.forEach((crop) => {
      const cropTypeSK = crop.cropType ? crop.cropType : crop.sk.substring(0, crop.sk.indexOf('_setchild_'));
      const cropType = cropTypes.find((c) => c.sk === cropTypeSK);
      const cropTypeName = cropType ? cropType.name : '';
      crop.varieties && crop.varieties.forEach((variety) => {
        const varietyCombo = `${variety.cultivarType} ${variety.cultivar} ${variety.rootStockType}`;
        rows.push({
          parentSK: crop.sk, sk: variety.sk, varietyCombo, cropType: cropTypeName, name: crop.name, cultivarType: variety.cultivarType, rootStockType: variety.rootStockType, rootStock: variety.rootStock, variety: variety.cultivar,
        });
      });
      // rows.push({ sk: `addrow${i}`, name: '', variety: this.addButton(crop) });
    });

    return rows;
  }

  createUnique = (coll, val) => {
    const arr = [];
    for (let i = 0; i < coll.length; i += 1) {
      if (arr.indexOf(coll[i][val]) === -1) {
        arr.push(coll[i][val]);
      }
    }
    return arr;
  }

  overrideTotalPlants = (event) => {
    const planting = { ...this.state.planting };
    planting.totalPlantsOverride = !planting.totalPlantsOverride;
    this.setState({ planting, changed: true });
  }

  render() {
    const {
      classes, open, isNew, setClose, handleUpdatePlantings, blockSK, metaData, allowDelete, handleDeletePlantings, block, getFromGlobalState,
    } = this.props;
    const {
      planting, errors, requiredFields, selectedCluster, selectedClusterItem, openedCluster, cultivarType, cultivarName, rootStockType, rootStockName,
    } = this.state;
    const formValid = validateForm(planting, requiredFields);

    const { crops } = metaData;
    if (!planting) return null;
    if (!planting.totalPlantsOverride && metaData.block) {
      planting.totalPlants = numberOfPlants(planting.plantSpacing, planting.plantDensity, (planting.size ? planting.size : metaData.block.size));
    }
    if (!planting.size) {
      planting.size = block.size;
    }

    const clusters = metaData.clusters ? metaData.clusters : [];

    return (

      <GenDialog
        open={open}
        dialogObject={planting}
        setClose={setClose}
        isNew={isNew}
        handleUpdateDialogObject={handleUpdatePlantings.bind(this)}
        skPrimary="_planting_"
        dynamoType="plantings"
        valid={formValid}
        changed={this.state.changed} // crops.find(crop => crop.sk === planting.crop)?.varieties
        newTitle="New Planting"
        subTitle={`Block ${block.number}  v.${planting.version ? planting.version : '0'}`}
        title={`${getNameFromKey2(planting.crop, metaData.crops, 'name')} - ${planting.variety ? getNameFromKey2(planting.variety, crops.find((crop) => crop.sk === planting.crop) && crops.find((crop) => crop.sk === planting.crop)?.varieties, 'cultivar') : ''} - ${planting.name ? planting.name : ''} `}
        parentKey={blockSK}
        allowDelete={allowDelete && handleDeletePlantings}
        handleDeleteDialogObject={handleDeletePlantings ? handleDeletePlantings.bind(this) : undefined}
        getFromGlobalState={getFromGlobalState}
      >
        <Grid container className={classes.root} spacing={1} alignItems="flex-start">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <FormControlLabel
          control={(
            <Switch
              checked={planting.disabled}
              onChange={this.handleSwitchChange}
              name="disabled"
              id="disabled"
              color="primary"
            />
        )}
          label="Disable Planting"
        />
        </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              type="text"
              id="name"
              label="Name"
              name="name"
              value={planting.name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.name ? true : undefined}
              helperText={errors.name}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper className={classes.cluster}>
              <InputLabel shrink>Available clusters</InputLabel>
              <FormControl className={classes.formControlC}>
                <InputLabel shrink htmlFor="crop">Cluster group</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  value={selectedCluster ? selectedCluster.sk : ''}
                  onChange={this.handleClusterChange}
                  onBlur={this.handleBlur}
                  inputProps={{
                    name: 'clusterGroup',
                    id: 'clusterGroup',
                  }}
                >
                  <option value="" />
                  {clusters && clusters.map((cluster) => (
                    <>
                      <option value={cluster.sk}>{cluster.name}</option>
                    </>
                  ))}

                </Select>
              </FormControl>

              <FormControl className={classes.formControlC}>
                <InputLabel shrink htmlFor="crop">Cluster</InputLabel>
                <Select
                  native
                  fullWidth
                  required
                  displayEmpty
                  value={selectedClusterItem ? selectedClusterItem.name : ''}
                  onChange={this.handleClusterItemChange}
                  onBlur={this.handleBlur}
                  inputProps={{
                    name: 'clusterGroupItem',
                    id: 'clusterGroupItem',
                  }}
                >
                  <option value="" />
                  {selectedCluster && selectedCluster.list && selectedCluster.list.map((item) => (
                    <>
                      <option value={item.sk}>{item.name}</option>
                    </>
                  ))}

                </Select>
              </FormControl>
              <FormControl className={classes.formControlC}>
                <Typography style={{ paddingLeft: '12px' }} variant="subtitle1">
                  Add to cluster
                  <Tooltip placement="top" title="Add to cluster">
                    <IconButton
                      disabled={!selectedCluster || !selectedClusterItem}
                      aria-label="Add"
                      color="secondary"
                      className={classes.menuButton}
                      onClick={this.handleAddCluster}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>

                </Typography>
              </FormControl>

            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormControl className={classes.formControl}>
              <Typography variant="subtitle1"> Clusters for this planting</Typography>
              <List component="nav" aria-label="main mailbox folders">
                {planting.clusters && planting.clusters.map((cluster) => (

                  <>
                    <ListItem button onClick={(e) => this.handleClusterOpen(cluster.sk)}>
                      <ListItemText primary={getNameFromKey2(cluster.sk, clusters, 'name')} />
                      {openedCluster === cluster.sk ? <ExpandLess /> : <ExpandMore />}
                      <IconButton
                        aria-label="Delete"
                        color="secondary"
                        className={classes.menuButton}
                        id={cluster.sk}
                        inputProps={{
                          name: cluster.sk,
                          id: cluster.sk,
                        }}
                        onClick={(e) => this.handleDeleteCluster(cluster.sk)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                    <Collapse in={openedCluster === cluster.sk} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {cluster.items && cluster.items.map((it) => (
                          <ListItem button className={classes.nested}>
                            <ListItemText primary={getNameFromKey2(it, clusters.find((c) => c.sk === cluster.sk)?.list, 'name')} />
                            <IconButton
                              aria-label="Delete"
                              color="secondary"
                              className={classes.menuButton}
                              id={it}
                              inputProps={{
                                name: it,
                                id: it,
                              }}
                              onClick={(e) => this.handleDeleteClusterItem(cluster.sk, it)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItem>

                        ))}

                      </List>
                    </Collapse>

                  </>

                ))}
              </List>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
              type="text"
           // fullWidth
              id="cluster"
              label="Clusters (Optional)"
              name="cluster"
              value={planting.cluster}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.cluster ? true : undefined}
              helperText={errors.cluster}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              margin="normal"
              required
              type="number"
              inputProps={{ min: '0.00001', step: 'any' }}
           // fullWidth
              id="size"
              label="Size (Hectares)"
              name="size"
              value={planting.size}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.size ? true : undefined}
              helperText={errors.size}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="crop">Crop</InputLabel>
              <Select
                native
                fullWidth
                required
                displayEmpty
                value={planting.crop}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={errors.crop ? true : undefined}
                inputProps={{
                  name: 'crop',
                  id: 'crop',
                }}
              >
                <option value="" />
                {crops && crops.map((crop) => (
                  <>
                    <option value={crop.sk}>{crop.name}</option>
                  </>
                ))}

              </Select>
              {errors.crop && <FormHelperText className={classes.helperText}>{errors.crop}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="cultivarType">Cultivar type</InputLabel>
              <Select
                native
                fullWidth
                required
                displayEmpty
                disabled={crops.filter((c) => c.sk === planting.crop).length === 0}
                value={cultivarType}
                onChange={this.handleLocalChange}
                inputProps={{
                  name: 'cultivarType',
                  id: 'cultivarType',
                }}
              >
                <option value="" />
                {crops && planting.crop && crops.find((c) => c.sk === planting.crop) && this.createUnique(crops.find((c) => c.sk === planting.crop).varieties, 'cultivarType').map((ct) => (
                  <>
                    <option value={ct}>{ct}</option>
                  </>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="cultivarName">Cultivar name</InputLabel>
              <Select
                native
                fullWidth
                required
                displayEmpty
                disabled={cultivarType === null}
                value={cultivarName}
                onChange={this.handleLocalChange}
                inputProps={{
                  name: 'cultivarName',
                  id: 'cultivarName',
                }}
              >
                <option value="" />
                {crops && planting.crop && crops.find((c) => c.sk === planting.crop) && this.createUnique(crops.find((c) => c.sk === planting.crop).varieties.filter((v) => v.cultivarType === cultivarType), 'cultivar').map((cultivar) => (
                  <>
                    <option value={cultivar}>{cultivar}</option>
                  </>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="cultivarName">Rootstock type</InputLabel>
              <Select
                native
                fullWidth
                required
                displayEmpty
                disabled={cultivarName === null}
                value={rootStockType}
                onChange={this.handleLocalChange}
                inputProps={{
                  name: 'rootStockType',
                  id: 'rootStockType',
                }}
              >
                <option value="" />
                {crops && planting.crop && cultivarType && cultivarName && this.createUnique(crops.find((c) => c.sk === planting.crop).varieties.filter((v) => v.cultivarType === cultivarType && v.cultivar === cultivarName), 'rootStockType').map((variety) => (
                  <>
                    <option value={variety}>{variety}</option>
                  </>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid style={{ padding: '10px' }} item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="cultivarName">Rootstock name</InputLabel>
              <Select
                native
                fullWidth
                required
                displayEmpty
                disabled={rootStockType === null}
                value={rootStockName}
                onChange={this.handleLocalChangeSetVariety}
                inputProps={{
                  name: 'rootStockName',
                  id: 'rootStockName',
                }}
              >
                <option value="" />
                {crops && planting.crop && cultivarType && cultivarName && rootStockType && this.createUnique(crops.find((c) => c.sk === planting.crop).varieties.filter((v) => v.cultivarType === cultivarType && v.cultivar === cultivarName && v.rootStockType === rootStockType), 'rootStock').map((variety) => (
                  <>
                    <option value={variety}>{variety}</option>
                  </>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor="variety">Variety</InputLabel>
              <Select
                native
                fullWidth
                required
                displayEmpty
                value={planting.variety}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={errors.variety ? true : undefined}
                inputProps={{
                  name: 'variety',
                  id: 'variety',
                }}
              >
                <option value="" />

                {planting.crop && crops && crops.find((crop) => crop.sk === planting.crop)?.varieties.map((variety) => (
                  <>
                    <option value={variety.sk}>{variety.cultivar}</option>
                  </>
                ))}

              </Select>
              {errors.crop && <FormHelperText className={classes.helperText}>{errors.crop}</FormHelperText>}
            </FormControl>
                </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required
              type="number"
              inputProps={{ min: '0.00001', step: 'any' }}
              style={{ minWidth: '150px' }}
            // fullWidth
              id="plantSpacing"
              label="Plant Spacing in m"
              name="plantSpacing"

              value={planting.plantSpacing}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.plantSpacing ? true : undefined}
              helperText={errors.plantSpacing}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

            <TextField
              margin="normal"
              required
              type="number"
              inputProps={{ min: '0.00001', step: 'any' }}
              style={{ minWidth: '150px' }}
              id="plantDensity"
              label="Row spacing in m"
              name="plantDensity"

              value={planting.plantDensity}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.plantDensity ? true : undefined}
              helperText={errors.plantDensity}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink={planting.totalPlants >= -1 ? true : undefined} htmlFor="totalPlants">Total plants in block</InputLabel>
              <TextField
                margin="normal"
                required
                style={{ minWidth: '150px' }}
                type="number"
                inputProps={{ min: '0.0000000001', step: 'any' }}
                id="totalPlants"
                name="totalPlants"
                disabled={!planting.totalPlantsOverride}

                value={planting.totalPlants}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                error={errors.totalPlants ? true : undefined}
                helperText={errors.totalPlants}
              />

            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControlLabel
              value="plantingOverride"
              control={(
                <Radio
                  color="secondary"
                  name="totalPlantsOverride"
                  checked={planting.totalPlantsOverride}
                  onClick={this.overrideTotalPlants}
                />
)}
              label="Override"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required

           // fullWidth
              type="date"
            // defaultValue={new Date()}
              id="date"
              label="Plant Date"
              name="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={planting.date}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.date ? true : undefined}
              helperText={errors.date}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              margin="normal"
              required

           // fullWidth
              type="date"
            // defaultValue={new Date()}
              id="harvestDate"
              label="Next harvest Date"
              name="harvestDate"
              InputLabelProps={{
                shrink: true,
              }}
              value={planting.harvestDate}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              error={errors.harvestDate ? true : undefined}
              helperText={errors.harvestDate}
            />
          </Grid>

        </Grid>
      </GenDialog>

    );
  }
}
PlantingD.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  planting: PropTypes.object,
  setClose: PropTypes.func.isRequired,
  isNew: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  handleUpdatePlantings: PropTypes.func.isRequired,
  handleDeletePlantings: PropTypes.func.isRequired,
  allowDelete: PropTypes.func,
  blockSK: PropTypes.string.isRequired,
  block: PropTypes.object,
  metaData: PropTypes.object.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,

};
export default withStyles(styles)(PlantingD);
