import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './App.css';
import { Auth } from 'aws-amplify';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Routes from './Routes';
import AppLayout from './components/AppLayout';
import theme from './utils/theme';
import { fromStore, records, synchronise } from './libs/storage';
import Load from './components/Load';
import ErrorBoundary from './components/ErrorBoundary';
import { Service } from './libs/offlineService';
/// import {createS3Instance } from './libs/s3Lib';
import "@flaticon/flaticon-uicons/css/all/all.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snack: { open: false, variant: 'info', messageText: '' },
      farmList:[],
      error: false,
      isAuthenticated: false,
      isAuthenticating: true,
      isLoading: false,
      synchronising: false,
      industryView: false,
      blocks: [],
      crops: [],
      farm: [],
      chemicals: [],
      fertilisers: [],
      pests: [],
      irrigations: [],
      chemequips: [],
      harvestequips: [],
      clusters: [],
      packequips: [],
      operators: [],
      agronomistActs: [],
      inv_chemical: [],
      inv_fertiliser: [],
      users: [],
      online: true,
      currentUser: {},
      siteList: [],
      activeSite: '',
      farms: [],
      tp: null,
      userDetail: null,
      monitorings: [],
      monitoringsOther: [],
      cropTypes: [],
      cropsDetail: [],
      genCrops: [],
      genTargets: [],
      myGenCrops: [],
      genVarieties: [],
      targetsTypesSetting: [],
      cropTypesSetting: [],
      genFertilisers: [],
      cropsSetting: [],
      productProcessorTypes: [],
      apiKeys: [],
      allfarms:[],
      productProcessors: [],
      myProductProcessors: [],
      combinations: [],
      filters: [],
      facilities: [],
      isMobile: false,
      targetDetail: {},
      census: null,
      imageUrls: {},
      filteredMetaData: {
        countries: [],
        provinces: [],
        regions: [],
        cropTypes: [],
        crops: [],
        varieties: [],
        cultivarTypes: [],
        cultivars: [],
        rootStockTypes: [],
        rootStocks: [],
      },
      metaData: {
        countries: [],
        provinces: [],
        regions: [],
        cropTypes: [],
        crops: [],
        varieties: [],
        cultivarTypes: [],
        cultivars: [],
        rootStockTypes: [],
        rootStocks: [],
      },
      selectedData: {
        countries: [],
        regions: [],
        provinces: [],
        cropTypes: [],
        crops: [],
        // varieties: [],
        cultivarTypes: [],
        cultivars: [],
        rootStockTypes: [],
        rootStocks: [],
        irrigationTypes: [],
      },
      allStats: {
        farms: 0,
        size: 0,
        plantings: 0,
        trees: 0,
        sizeByCrop: {},
        set: false,
      },
      filteredStats: {
        farms: 0,
        size: 0,
        plantings: 0,
        trees: 0,
        set: false,
      },
    };
    this.baseState = this.state;
  }

  async currentIdentity() {
    const cachePrefix = `CognitoIdentityServiceProvider.${'6io74e3s3ok97to2pk8uj9abic'}`;
    const lastUser = localStorage.getItem(`${cachePrefix}.LastAuthUser`);
    if (lastUser == null) return null;

    const idToken = localStorage.getItem(`${cachePrefix}.${lastUser}.idToken`);
    if (idToken == null) throw Error('Failed to read IdToken from cache!');

    const identity = JSON.parse(atob(idToken.split('.')[1]));

    const expires = identity.exp;
    const clockDrift = Number(localStorage.getItem(`${cachePrefix}.${lastUser}.clockDrift`) || 0);

    const now = Math.ceil(Date.now() / 1000);
    if (expires + clockDrift < now) {
      const session = await Auth.currentSession();
      if (session == null) return null;
      return session.getIdToken().decodePayload();
    }

    return identity;
  }

   connectionChange = (e) => {
     const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
     const conn = `effectiveType ${connection.effectiveType} downlink ${connection.downlink} rtt ${connection.rtt}`;
     alert(conn);
   }

   componentWillUnmount() {
     window.removeEventListener('online', this.updateOnlineStatus);
     window.removeEventListener('offline', this.updateOnlineStatus);
   }

   updateOnlineStatus = () => {
    const condition = !!navigator.onLine;
    this.setState({ online: condition });
    // const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    // const conn = `effectiveType ${connection.effectiveType} downlink ${connection.downlink} rtt ${connection.rtt}`;
    // alert (conn);
  };

   async componentDidMount() {
     try {
       this.updateOnlineStatus();
       window.addEventListener('online', this.updateOnlineStatus.bind(this));
       window.addEventListener('offline', this.updateOnlineStatus.bind(this));

       await Auth.currentSession();

       await this.userHasAuthenticated(true);

       const isMobile = !window.matchMedia('(min-width: 850px)').matches;
       this.setState({ isMobile });
     } catch (e) {
       if (e !== 'No current user') {
         // alert(e);
         this.setSnack({ open: true, variant: 'error', messageText: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error} ` : e.message) });
       }
       this.setState({ isAuthenticating: false, isLoading: false });
     }

     //
   }

   sync = async (activeSite) => {
     if (this.state.isAuthenticated) {
       this.setState({ synchronising: true });
       try {
         this.setSnack({ open: true, variant: 'info', messageText: 'Synchronising..' });
         const recordsLoaded = await synchronise(this.state.currentUser.username, 'farms', activeSite);
         let industryView = false;
         if (recordsLoaded.records && recordsLoaded.records.industryView) {
           if (recordsLoaded.records.invites && recordsLoaded.records.invites.status && recordsLoaded.records.invites.result) {
             const initData = await fromStore(recordsLoaded.records.invites.result);
             this.setState({ invites: initData.invites });
           }
           industryView = true;
         }
         if (!industryView && recordsLoaded.status) {
           const initData = await fromStore(recordsLoaded.records);
           const userDetail = initData.users && initData.users.find((user) => user?.email === this.state.tp.attributes.email);
           if (!userDetail) {
             console.log('could not find user detail in activesite');
           }
           if (!activeSite || activeSite === '' || activeSite === 'default') {
             activeSite = initData.activeSite;
           }


           this.setState(
             {
               allfarms:initData.allFarms,
               blocks: initData.blocks,
               crops: initData.crops,
               farm: initData.farm,
               chemicals: initData.chemicals,
               fertilisers: initData.fertilisers,
               pests: initData.pests,
               monitorings: initData.monitorings,
               monitoringsOther: initData.monitoringsOther,
               irrigations: initData.irrigations,
               chemequips: initData.chemequips,
               harvestequips: initData.harvestequips,
               clusters: initData.clusters,
               packequips: initData.packequips,
               operators: initData.operators,
               agronomistActs: initData.agronomistActs,
               inv_chemical: initData.inv_chemical,
               inv_fertiliser: initData.inv_fertiliser,
               users: initData.users,
               invites: initData.invites,
               sentInvites: initData.sentInvites,
               sentSharedInvites: initData.sentSharedInvites,
               folders: initData.folders,
               files: initData.files,
               farms: initData.farms,
               siteList: initData.siteList && initData.siteList.length > 0 ? initData.siteList : this.state.siteList,
               activeSite: activeSite !== '' ? activeSite : initData.activeSite,
               userDetail,
               myGenCrops: initData.myGenCrops,
               genVarieties: initData.genVarieties,
               targetsTypesSetting: initData.targetsTypesSetting,
               cropTypesSetting: initData.cropTypesSetting,
               cropsSetting: initData.cropsSetting,
               productProcessorTypes: initData.productProcessorTypes,
               productProcessors: initData.productProcessors,
               filters: initData.filters,
               myProductProcessors: initData.myProductProcessors,
               facilities: initData.facilities,
               apiKeys: initData.apiKeys,
               combinations: initData.combinations,
             },
           );
           //   const userDetail = this.state.users && this.state.users.find((user) => user?.email === this.state.tp.attributes.email);
           // if (userDetail){
           //   this.setState({userDetail});
           // }
           if (recordsLoaded.message) {
             this.setSnack({
               open: true, variant: 'warning', messageText: recordsLoaded.message, autoHideDuration: 3000,
             });
           } else {
             this.setSnack({ open: false, variant: 'info', messageText: '' });
             this.setState({ online: true });
           }
         } else if (industryView) {
           this.setSnack({
             open: true, variant: 'info', autoHideDuration: 2000, messageText: 'Loading data',
           });
           this.setState({ synchronising: false });
         } else {
           this.setSnack({
             open: true, variant: 'info', autoHideDuration: 2000, messageText: 'Poor connection working offline',
           });
           this.setState({ online: false });
         }

         this.setState({ synchronising: false, industryView });
       } catch (e) {
         this.setState({ synchronising: false });
         // alert(JSON.stringify(e));
         // this.setSnack({ open: true, variant: 'error', messageText: JSON.stringify(e) }); //debug
         this.setSnack({ open: true, variant: 'error', messageText: (e.response ? `${e.response.data?.error?.message ? e.response.data?.error?.message : e.response?.data?.error} ` : e?.message) });
         /// /this.setState({ online: false, synchronising: false });
         this.setState({ synchronising: false });
         // alert(e);
       }
     }
   }

  userHasAuthenticated = async (authenticated) => {
    this.setState({ isAuthenticated: authenticated, isAuthenticating: false, isLoading: true });
    // get tenant list here
    // let siteList = await getSiteList();
    // siteList = siteList ? siteList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
    // let activeSite = this.state.activeSite;
    // activeSite = activeSite !== '' ? activeSite : siteList[siteList.length-1].tenantId;
    //  this.setState({activeSite});
    // const essentialCreds = Auth.essentialCredentials(creds);
    // const s3Instance = createS3Instance(essentialCreds);
    // this.setState({ s3Instance });
    // determine the type of user here.
    var activeSite = this.state.activeSite;
   // if (activeSite ===''){
   //   const v = await Service.getAllFlat(this.state.currentUser.username, 'defaults');
   //   activeSite = v[0].activeSite;
   // }

    const newActiveSite = await this.refreshState(activeSite);
   
    this.setState({ isLoading: false });
    await this.sync(newActiveSite);
  }

  setGlobalState = (stateObject) => {
    this.setState(stateObject);
    // this.sync(this.state.activeSite);
  }

  setSnack = (snack) => {
    if (snack.variant === '') {
      const bladi = true;
    }
    this.setState({ snack });
  }

  getFromGlobalState = (stateMember) => this.state[stateMember]

  reload = () => {
    window.location.reload(true);
  }
  // todo implement multitenancy properly - by passing tenantId in token.
  // setUserFarmId = farmId => {
  // this.setState( { farmId: farmId });
  // }

  handleLogout = async (event) => {
    const { online } = this.state;
    if (online) {
      await Auth.signOut();
      // await Service.clear();

      this.setState(this.baseState);
      this.userHasAuthenticated(false);
      this.setState({ isLoading: false, isAuthenticating: false });

      // this.setUserFarmId('');
      this.props.history.push('/login');
    }
  }

  async refreshState(activeSite) {
    if (this.state.isAuthenticated) {
      try {
        // this.setState({ isLoading: true });
        const { tp } = this.state;
        if (!tp) {
          const tp = await Auth.currentAuthenticatedUser();
          this.setState({ tp, currentUser: { username: tp.username, email: tp.attributes.email } });
        }

          if (activeSite ===''){
            const v = await Service.getAllFlat(this.state.currentUser.username, 'defaults');
            activeSite = v[0] && v[0].activeSite ? v[0].activeSite : '';
        }

        console.time('timer');
        const recordsLoaded = await records(this.state.currentUser.username, 'farms', activeSite);

        // console.log("local storage updated");
        if (!recordsLoaded) {
          return null;
        }
        const initData = await fromStore(recordsLoaded.records);

        const userDetail = initData.users && initData.users.find((user) => user?.email === this.state.tp.attributes.email);
        if (!userDetail) {
          console.log('could not find user detail');
        }

        this.setState({
          blocks: initData.blocks,
          crops: initData.crops,
          farm: initData.farm,
          chemicals: initData.chemicals,
          fertilisers: initData.fertilisers,
          pests: initData.pests,
          monitorings: initData.monitorings,
          monitoringsOther: initData.monitoringsOther,
          irrigations: initData.irrigations,
          chemequips: initData.chemequips,
          harvestequips: initData.harvestequips,
          clusters: initData.clusters,
          packequips: initData.packequips,
          operators: initData.operators ? initData.operators : [],
          agronomistActs: initData.agronomistActs ? initData.agronomistActs : [],
          inv_chemical: initData.inv_chemical,
          inv_fertiliser: initData.inv_fertiliser,
          users: initData.users,
          invites: initData.invites,
          folders: initData.folders,
          files: initData.files,
          sentInvites: initData.sentInvites,
          sentSharedInvites: initData.sentSharedInvites,
          activeSite: activeSite !== '' ? activeSite : initData.activeSite,
          siteList: initData.siteList,
          userDetail,
          myGenCrops: initData.myGenCrops,
          genVarieties: initData.genVarieties,
          targetsTypesSetting: initData.targetsTypesSetting,
          cropTypesSetting: initData.cropTypesSetting,
          cropsSetting: initData.cropsSetting,
          facilities: initData.facilities,
          productProcessorTypes: initData.productProcessorTypes,
          productProcessors: initData.productProcessors,
          filters: initData.filters,
          myProductProcessors: initData.myProductProcessors,
          activeSite: activeSite,
          apiKeys: initData.apiKeys,
          combinations: initData.combinations,

        });

        console.timeEnd('timer');
        // this.setState({ isLoading: false });
      } catch (e) {
        if (e !== 'No current user') {
          this.setSnack({ open: true, variant: 'error', messageText: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error} ` : e.message) });
          // alert(e);
        } else {
          this.setSnack({ open: true, variant: 'error', messageText: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error} ` : e.message) });
        // this.setSnack({ open: true, variant: 'error', messageText: JSON.stringify(e) })
          // alert(e);
        }
      }
    }
    return activeSite;
  }

  async refreshIndustryView() {
    const { isAuthenticated } = this.state;
    if (isAuthenticated) {
      try {

      } catch (e) {
        if (e !== 'No current user') {
          this.setSnack({ open: true, variant: 'error', messageText: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error} ` : e.message) });
        } else {
          this.setSnack({ open: true, variant: 'error', messageText: (e.response ? `${e.response.data.error.message ? e.response.data.error.message : e.response.data.error} ` : e.message) });
        }
      }
    }
  }

  render() {

     const farm = this.getFromGlobalState('farm');
    const siteList = this.getFromGlobalState('siteList')
    const confirmed = siteList.length === 1
    const isEntity = !!(farm && (farm[0] && farm[0].entityType && farm[0].entityType === 'entity'));
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setUserFarmId: this.setUserFarmId,
      setGlobalState: this.setGlobalState,
      getFromGlobalState: this.getFromGlobalState,
      farmId: this.state.farmId,
      reload: this.reload,
      refreshState: this.refreshState.bind(this),
      sync: this.sync.bind(this),
      setSnack: this.setSnack,
      snack: this.state.snack,
      isEntity: isEntity,
      online: this.state.online,
      activeSite: this.state.activeSite,
      userDetail: this.state.userDetail,
      isMobile: this.state.isMobile,
      industryView: this.state.industryView,
    };
    

    return (
      <ErrorBoundary>

        <MuiThemeProvider theme={theme}>
          <Load isloading={this.state.isLoading || this.state.isAuthenticating}>
            {!(this.state.isAuthenticating || this.state.isLoading)
    && (
    <AppLayout industryView={childProps.industryView} isMobile={childProps.isMobile} isAuthenticated={childProps.isAuthenticated} userDetail={childProps.userDetail} sync={childProps.sync} refreshState={childProps.refreshState} online={childProps.online} reload={this.reload} setGlobalState={this.setGlobalState} getFromGlobalState={this.getFromGlobalState} snack={childProps.snack} setSnack={childProps.setSnack} handleLogout={this.handleLogout}>
      <Routes childProps={childProps}/>
    </AppLayout>
    ) }
          </Load>

        </MuiThemeProvider>

      </ErrorBoundary>
    );
  }
}
export default withRouter(App);

// export default App;
