import {
  green, red, amber, grey,
} from '@material-ui/core/colors';
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import { getNameFromKey2, getObjectFromKey, getLocale, calculateMidpoint } from './genLib';
import { sortOutAverages } from '../components/ReportByActivity';

// https://stackoverflow.com/questions/14446511/most-efficient-method-to-groupby-on-an-array-of-objects
export function groupBy(xs, key) {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function groupByMultiple(items, groups) {
  const grouped = {};
  if (!items && !items.length) {
    return grouped;
  }
  items.forEach((a) => {
    groups.reduce((o, g, i) => { // take existing object,
      o[a[g]] = o[a[g]] || (i + 1 === groups.length ? [] : {}); // or generate new obj, or
      return o[a[g]]; // at last, then an array
    }, grouped).push(a);
  });
  return grouped;
}

function getAvatar(status) {
  if (status === 'green') {
    const title = 'Value is within threshold';
    return (
      {
        icon:
  <Tooltip placement="top" title={title}>
    <Avatar style={{
      margin: 3,
      color: '#fff',
      backgroundColor: green[500],
      width: 15,
      height: 15,
      // boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    }}
    >
      <checkIcon />
    </Avatar>
  </Tooltip>,
        ragOrder: 2,
      }
    );
  }
  if (status === 'orange') {
    const title = 'Value is close to threshold';
    return (
      {
        icon:
  <Tooltip placement="top" title={title}>
    <Avatar style={{
      margin: 3,
      color: '#fff',
      backgroundColor: amber[500],
      width: 15,
      height: 15,
      // boxShadow:'0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    }}
    >
      <checkIcon />
    </Avatar>
  </Tooltip>,
        ragOrder: 1,
      }
    );
  }
  if (status === 'red') {
    const title = 'Value exceed threshold';
    return (
      {
        icon:
  <Tooltip placement="top" title={title}>
    <Avatar style={{
      margin: 3,
      color: '#fff',
      backgroundColor: red[500],
      width: 15,
      height: 15,
      // boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    }}
    >
      <checkIcon />
    </Avatar>
  </Tooltip>,
        ragOrder: 0,
      }
    );
  }
  return (
    {
      icon:
  <Tooltip placement="top" title="Value does not have a threshold">
    <Avatar style={{
      margin: 3,
      color: '#fff',
      backgroundColor: grey[500],
      width: 15,
      height: 15,
      // boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    }}
    >
      <checkIcon />
    </Avatar>
  </Tooltip>,
      ragOrder: 3,
    }
  );
}

const iterate = (obj, sumField, round, constant, operation) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (Array.isArray(obj[key])) {
        let sum = obj[key].reduce((accumulator, row) => accumulator + parseFloat(row[sumField]), 0);
        if (isNaN(sum)) {
          sum = -1000;
        }
        if (constant) {
          const constVal = obj[key].reduce((accumulator, row) => accumulator + parseFloat(row[constant]), 0);
          if (isNaN(constVal)) {
            // const blee = 'lee';
          }
          if (operation === 'divide') {
            sum /= constVal;
          }
        }

        obj[key] = sum;
        if (round) {
          obj[key] = Math.round(obj[key]);
        }
      }
      iterate(obj[key], sumField, round, constant, operation);
    }
  });
};

export function groupByMultipleSum(items, groups, sumField, resultName, round, constant, operation) {
  const groupsObj = groupByMultiple(items, groups);

  iterate(groupsObj, sumField, round, constant, operation);

  const result = {};
  result.data = [];
  result.keys = [];
  const grouped = {};
  if (!items && !items.length) {
    return grouped;
  }
  Object.keys(groupsObj).forEach((key) => {
    const ob = {};
    ob.name = key;
    Object.keys(groupsObj[key]).forEach((key2) => {
      ob[key2] = groupsObj[key][key2];
      const idx = result.keys.indexOf(key2);
      if (idx === -1) {
        result.keys.push(key2);
      }
    });
    result.data.push(ob);
  });
  result.name = resultName;
  return result;
}

export function groupByMultipleSum2(items, groups, sumField, resultName, round) {
  // const groupsObj = groupByMultiple(items, groups);
  const result = {};
  result.data = [];
  result.keys = [];
  const grouped = {};
  if (!items && !items.length) {
    return grouped;
  }
  items.forEach((a) => {
    groups.reduce((o, g, i) => { // take existing object,
      o[a[g]] = o[a[g]] || (i + 1 === groups.length ? [] : {}); // or generate new obj, or
      if (Array.isArray(o[a[g]])) {
        o[a[g]][sumField] = o[a[g]].reduce((accumulator, row) => accumulator + parseFloat(row[sumField]), 0);
        if (round) {
          o[a[g]][sumField] = Math.round(o[a[g]][sumField]);
        }
      }
      return o[a[g]]; // at last, then an array
    }, grouped).push(a);
  });

  Object.keys(grouped).forEach((key) => {
    const ob = {};
    ob.name = key;
    Object.keys(grouped[key]).forEach((key2) => {
      ob[key2] = grouped[key][key2][sumField];
      const idx = result.keys.indexOf(key2);
      if (idx === -1) {
        result.keys.push(key2);
      }
    });
    result.data.push(ob);
  });
  result.name = resultName;
  return result;
}

export function groupBySum(xs, key, sumField, friendlyName, resultName, round) {
  const groupObj = groupBy(xs, key);
  const result = {};
  result.data = [];
  result.keys = [];
  Object.keys(groupObj).forEach((obKey) => {
    let total = groupObj[obKey].reduce((accumulator, row) => accumulator + parseFloat(row[sumField]), 0);
    if (round) {
      total = Math.round(total);
    }
    const ob = {};
    ob.name = obKey;
    ob[friendlyName] = total;
    result.data.push(ob);
  });
  result.keys.push(friendlyName);
  result.name = resultName;
  return result;
}

export function groupByCount(xs, key, sumField, friendlyName, resultName, round) {
  const groupObj = groupBy(xs, key);
  const result = {};
  result.data = [];
  result.keys = [];
  Object.keys(groupObj).forEach((obKey) => {
    let total = groupObj[obKey].length;
    if (round) {
      total = Math.round(total);
    }
    const ob = {};
    ob.name = obKey;
    ob[friendlyName] = total;
    result.data.push(ob);
  });
  result.keys.push(friendlyName);
  result.name = resultName;
  return result;
}

/* function groupBy1(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
} * /

/* let groupBy2 = function (data, key) {
  const res = data.reduce((acc, curr) => {
    if (!acc[curr.account.type]) acc[curr.account.type] = []; // If this type wasn't previously stored
    acc[curr.account.type].push(curr);
    return acc;
  }, {});
}; */

// TODO: make more efficient
export function cropsPlantedByType(blocks, idMaps) {
  // let retVal = groupBy(blocks,'number');
  // loop over blocks - get their sizes - add all the crops inside them group by crop.
  let retVal = [];

  blocks.forEach((block) => {
    if (!block.plantings || block.plantings.length === 0) {
      const i = retVal.findIndex((r) => r.name === 'Not Planted');
      if (i !== -1) {
        retVal[i] = { name: 'Not Planted', value: retVal[i].value + parseFloat(block.size) };
      } else {
        retVal.push({ name: 'Not Planted', varietyName: 'Not Planted', value: parseFloat(block.size) });
      }
    } else {
      block.plantings.forEach((planting) => {
        const cropName = idMaps.find((crop) => crop.sk === planting.crop);

        const i = retVal.findIndex((r) => r.name === cropName.name);

        if (i !== -1) {
          retVal[i] = { name: cropName.name, value: retVal[i].value + parseFloat(planting.size ? planting.size : block.size) };
        } else {
          retVal.push({ name: cropName.name, value: parseFloat(planting.size ? planting.size : block.size) });
        }
      });
    }
  });

  // now sum it - sure there is a better way.
  retVal = retVal ? retVal.map((item) => {
    const ob = { ...item };
    ob.value = Math.round((item.value + Number.EPSILON) * 100) / 100;
    return ob;
  }) : [];

  // console.log('retval',retVal) ;
  return retVal.sort((a, b) => {
    if (a.name < b.name) { return -1; }
    if (a.name > b.name) { return 1; }
    return 0;
  });
  // return retVal.sort();
}
export function cropsPlantedByVariety(blocks, idMaps) {
  // let retVal = groupBy(blocks,'number');
  // loop over blocks - get their sizes - add all the crops inside them group by crop.
  let retVal = [];

  blocks.forEach((block) => {
    if (!block.plantings || block.plantings.length === 0) {
      const i = retVal.findIndex((r) => r.name === 'Not Planted');
      if (i !== -1) {
        retVal[i] = { cropName: 'Not Planted', name: 'Not Planted', value: retVal[i].value + parseFloat(block.size) };
      } else {
        retVal.push({
          cropName: 'Not Planted', name: 'Not Planted', varietyName: 'Not Planted', value: parseFloat(block.size),
        });
      }
    } else {
      block.plantings.forEach((planting) => {
        const cropName = idMaps.find((crop) => crop.sk === planting.crop);
        const varietyName = cropName.varieties.find((variety) => variety.sk === planting.variety);
        const i = retVal.findIndex((r) => r.name === varietyName.name);
        if (i !== -1) {
          retVal[i] = {
            cropName: cropName.name,
            name: varietyName.name,
            value: retVal[i].value + parseFloat(planting.size ? planting.size : block.size),
          };
        } else {
          retVal.push({
            cropName: cropName.name,
            name: varietyName.name,
            value: parseFloat(planting.size ? planting.size : block.size),
          });
        }
      });
    }
  });

  // now sum it - sure there is a better way.
  retVal = retVal ? retVal.map((item) => {
    const ob = { ...item };
    ob.value = Math.round((item.value + Number.EPSILON) * 100) / 100;
    return ob;
  }) : [];

  return retVal.sort((a, b) => {
    if (a.cropName < b.cropName) { return -1; }
    if (a.cropName > b.cropName) { return 1; }
    return 0;
  });

  // return retVal;
}
function getWorstCaseStatus(currentStatus, newStatus) {
  const status = { red: 3, amber: 2, green: 1 };
  const current = status[currentStatus];
  const newS = status[newStatus];
  if (current > newS) {
    return currentStatus;
  }

  return newStatus;
}

function getAge(plantDate) {
  const years = new Date(new Date() - new Date(plantDate)).getFullYear() - 1970;
  let bucket = '';
  switch (true) {
    case (years > 0 && years <= 3): {
      bucket = '0 - 3';
      break;
    }
    case (years > 3 && years) <= 6: {
      bucket = '3 - 6';
      break;
    }
    case (years > 6 && years) <= 9: {
      bucket = '6 - 9';
      break;
    }
    case (years > 9 && years <= 12): {
      bucket = '9 - 12';
      break;
    }
    case (years > 12 && years <= 15): {
      bucket = '12 - 15';
      break;
    }
    case (years > 15 && years <= 18): {
      bucket = '15 - 18';
      break;
    }
    case (years > 18 && years <= 21): {
      bucket = '18 - 21';
      break;
    }
    case (years > 21 && years <= 24): {
      bucket = '21 - 24';
      break;
    }
    case (years > 24): {
      bucket = '24 +';
      break;
    }
    default: {
      bucket = 'unknown';
    }
  }
  return bucket;
}

function getCommonRecordData(planting, block, record, displayName) {
  const rowObject = {};
  rowObject.cluster = planting.cluster ? planting.cluster : 'None';
  rowObject.blockNumber = block.number;
  rowObject.planting = planting.name;
  rowObject.recordType = record.type;
  rowObject.recordTypeDisplay = displayName;
  rowObject.date = record.date;
  rowObject.time = record.time;
  rowObject.recordSK = record.sk;
  rowObject.blockSK = block.sk;
  rowObject.plantingSK = planting.sk;
  rowObject.createdBy = `${record.createdByDetail.name}  ${record.createdByDetail.surname} `;
  rowObject.operator = record.operator ? record.operator : 'No operator';
  return rowObject;
}

export function flattenRecords(blocks) {
  if (!blocks) {
    return null;
  }
  const flatItems = [];
  let key = 0;

  // const rowObject = {};
  for (let b = 0; b < blocks.length; b += 1) {
    const block = blocks[b];
    if (!block.paths) {
      continue;
    }
    for (let p = 0; block.plantings && block.plantings.length && p < block.plantings.length; p += 1) {
      const planting = block.plantings[p];

      // pestings
      for (let pest = 0; planting.pestings && pest < planting.pestings.length; pest += 1) {
        const pesting = planting.pestings[pest];

        const rowObject = getCommonRecordData(planting, block, pesting, 'Monitoring');
        rowObject.key = key;
        key += 1;
        flatItems.push(rowObject);
      }
      // waterings
      for (let w = 0; planting.waterings && w < planting.waterings.length; w += 1) {
        const watering = planting.waterings[w];
        const rowObject = getCommonRecordData(planting, block, watering, 'Irrigation');
        rowObject.key = key;
        key += 1;
        flatItems.push(rowObject);
      }

      /* rowObject.cropType = planting.crop.cropType;
      rowObject.cropTypeName = planting.crop.cropTypeName;
      rowObject.cropName = planting.crop.name;
      rowObject.cultivarType = planting.variety.cultivarType;
      rowObject.cultivar = planting.variety.cultivar;
      rowObject.varietyId = planting.variety.id;
      rowObject.rootStockType = planting.variety.rootStockType;
      rowObject.rootStock = planting.variety.rootStock; */
    }
  }

  return flatItems;
}

export function numberOfPlants(plantSpacing, rowSpacing, blockSize) {
  if (plantSpacing && rowSpacing && blockSize) {
  // cm to m.
    const populationPerHa = 10000 / ((plantSpacing) * rowSpacing);
    const totalPopulation = blockSize * populationPerHa;
    return Math.trunc(totalPopulation);
  }
  return undefined;
}

export function flattenItems(items) {
  // const { stats } = this.state;

  if (!items) {
    return null;
  }
  const flatItems = [];
  let key = 0;

  for (let i = 0; i < items.length; i += 1) {
    if (!items[i].blocks || !items[i].farm) {
      continue;
    }
    // const rowObject = {};
    for (let b = 0; b < items[i].blocks.length; b += 1) {
      if (!items[i].blocks[b].paths) {
        continue;
      }
      if (items[i].blocks[b].plantings) {
        for (let p = 0; p < items[i].blocks[b].plantings.length; p += 1) {
          const rowObject = {};
          // farm
          rowObject.country = items[i].farm.address.country;
          if (rowObject.country === 'Unknown') {
            console.log('No country for', items[i].farm.shortName);
          }
          rowObject.key = key;
          key += 1;
          rowObject.province = items[i].farm.address.province;
          rowObject.farmId = items[i].farm.id;
          rowObject.geoLocation = items[i].farm.geoLocation;
          // blocks
          rowObject.blockId = items[i].blocks[b].id;
          // rowObject.irrigated = items[i].blocks[b].irrigated;
          rowObject.slope = items[i].blocks[b].slope;
          rowObject.soilType = items[i].blocks[b].soilType;
          rowObject.numPlantings = items[i].blocks[b].plantings.length;
          // plantings
          rowObject.plantingId = items[i].blocks[b].plantings[p].id;
          rowObject.date = items[i].blocks[b].plantings[p].date;
          rowObject.ageBucket = getAge(rowObject.date);
          rowObject.flowunit = items[i].blocks[b].plantings[p].flowunit;
          rowObject.volumePerHour = items[i].blocks[b].plantings[p].volumePerHour;
          rowObject.harvestDate = items[i].blocks[b].plantings[p].harvestDate;
          rowObject.irrigated = items[i].blocks[b].plantings[p].irrigated;
          rowObject.irrigationSystem = items[i].blocks[b].plantings[p].irrigationSystem;
          rowObject.plantDensity = items[i].blocks[b].plantings[p].plantDensity;
          if (!rowObject.plantDensity) {
            console.log('No density for', items[i].farm.shortName);
          }

          rowObject.plantSpacing = items[i].blocks[b].plantings[p].plantSpacing;
          if (!rowObject.plantSpacing) {
            console.log('No spacing for', items[i].farm.shortName);
          }
          rowObject.totalPlantsPerHa = items[i].blocks[b].plantings[p].totalPlantsPerHa;
          rowObject.size = parseFloat(items[i].blocks[b].plantings[p].size);
          if (isNaN(rowObject.size)) {
            rowObject.size = parseFloat(items[i].blocks[b].sizeMap);// / items[i].blocks[b].plantings.length;
          }
          rowObject.totalPlants = items[i].blocks[b].plantings[p].totalPlants;
          if (!rowObject.totalPlants || isNaN(rowObject.totalPlants)) {
            rowObject.totalPlants = numberOfPlants(rowObject.plantSpacing, rowObject.plantDensity, rowObject.size);
          }
          if (!rowObject.totalPlantsPerHa || isNaN(rowObject.totalPlantsPerHa)) {
            rowObject.totalPlantsPerHa = rowObject.totalPlants / rowObject.size;
          }
          rowObject.cropType = items[i].blocks[b].plantings[p].crop.cropType;
          rowObject.cropTypeName = items[i].blocks[b].plantings[p].crop.cropTypeName;
          rowObject.cropName = items[i].blocks[b].plantings[p].crop.name;
          rowObject.cultivarType = items[i].blocks[b].plantings[p].variety.cultivarType;
          rowObject.cultivar = items[i].blocks[b].plantings[p].variety.cultivar;
          rowObject.varietyId = items[i].blocks[b].plantings[p].variety.id;
          rowObject.rootStockType = items[i].blocks[b].plantings[p].variety.rootStockType;
          rowObject.rootStock = items[i].blocks[b].plantings[p].variety.rootStock;
          flatItems.push(rowObject);
        }
      }
    }
  }
  return flatItems;
}

export function arrayToCSV(rows) {
  let csv = '';
  const header = Object.keys(rows[0]).join(',');
  const values = rows.map((o) => Object.values(o).join(',')).join('\n');

  csv += `${header}\n${values}`;

  const csvContent = `data:text/csv;charset=utf-8,${
    csv}`;

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'my_data.csv');
  document.body.appendChild(link); // Required for FF

  link.click();
}
export function statusEngine(blocks) {
  const today = new Date();
  // const statusInfo ={status:'green',messages: []};

  if (!blocks) { return; }
  for (const block of blocks) {
    const blockStatusInfo = { status: 'green', messages: [] };
    if (!block.plantings) { continue; }
    for (const planting of block.plantings) {
      const plantingStatusInfo = { status: 'green', messages: [] };
      // sprayings start
      if (!planting.sprayings) { continue; }
      for (const spraying of planting.sprayings) {
        const sprayingStatusInfo = { status: 'green', message: null };
        if (spraying.phiLapse !== undefined) {
          const lapseDate = new Date(spraying.phiLapse.substring(0, 10));// todo: fix this, need time
          if (lapseDate > today) {
            sprayingStatusInfo.status = 'red';
            sprayingStatusInfo.message = 'In pre-harvest Interval';
          }
        } else {
          sprayingStatusInfo.status = 'red';
          sprayingStatusInfo.message = 'Pre-harvest date not defined';
        }
        spraying.status = sprayingStatusInfo;
        // set planting status //todo: set only the worst case status
        plantingStatusInfo.status = getWorstCaseStatus(plantingStatusInfo.status, sprayingStatusInfo.status);
        if (sprayingStatusInfo.message) {
          plantingStatusInfo.messages.push(sprayingStatusInfo.message);
        }

        // spraying loop done
      }
      blockStatusInfo.status = getWorstCaseStatus(blockStatusInfo.status, plantingStatusInfo.status);
      if (plantingStatusInfo.messages.length > 0) {
        blockStatusInfo.messages.push(plantingStatusInfo.messages);
      }
      planting.status = plantingStatusInfo;

      // plantings loop done
    }
    block.status = blockStatusInfo;

    // blocks loop done
  }
}
export function inPHI(block) {
  // for this block see if there are plantings that has
  // chemical activity
  // and for each one that has chemical activity
  // calculate if we are inside the PHI.
  // return all plantings and chemical activities for which this is
  // the case.
  // todo: see if we need to rather use a date from the server - if the
  // user system date is wrong - he might miss a block.
  const today = new Date();
  // const retVal = {
  //  inPHI: 'no', plantingsInPHI: [], plantingsInPHIUncertain: [], sprayingsInPHI: [], sprayingsInPHIUncertain: [],
  // };
  if (!block.plantings) {
    return false;
  }
  for (const planting of block.plantings) {
    if (!planting || !planting.sprayings) {
      return true;
    }
    for (const spraying of planting.sprayings) {
      if (spraying.phiLapse !== undefined) {
        const lapseDate = new Date(spraying.phiLapse.substring(0, 10));// todo: fix this, need time
        if (lapseDate > today) {
          // retVal.inPHI='yes';
          // retVal.plantingsInPHI.push(planting.sk);
          // retVal.sprayingsInPHI.push(spraying.sk);
          return true;
        }
      } else {
        return true;
      }
    }
  }
  return false;
  /* block.plantings.forEach(function (planting) {
    planting.sprayings.forEach (function (spraying){
      if (spraying.phiLapse !== undefined)
      {
        const lapseDate = new Date(spraying.phiLapse);
        if (lapseDate > today)
        {
          //retVal.inPHI='yes';
          //retVal.plantingsInPHI.push(planting.sk);
          //retVal.sprayingsInPHI.push(spraying.sk);
          return true;
        }
      }
      else {
        // we have a spraying but we do not have a lapse for phi.
        //retVal.plantingsInPHIUncertain.push(spraying.sk);
        //retVal.sprayingsInPHIUncertain.push(spraying.sk);
        //retVal.inPHI='maybe';
        return true;

      }
    });
  }); */

  // return false;
}
function copyObjectFields(originObject, fieldNamesArray) {
  const obj = {};

  if (fieldNamesArray === null) { return obj; }

  for (let i = 0; i < fieldNamesArray.length; i += 1) {
    obj[fieldNamesArray[i]] = originObject[fieldNamesArray[i]];
  }

  return obj;
}
export function getBlockCollectionByName(blocks, collectionName, collectionMembers, plantingMembers, blockMemebers) {
  const retVal = [];
  if (!blocks) { return []; }
  blocks.forEach((block) => {
    block.plantings && block.plantings.forEach((planting) => {
      planting[collectionName] && planting[collectionName].forEach((coll) => {
        retVal.push({
          [collectionName]: copyObjectFields(coll, collectionMembers),
          planting: copyObjectFields(planting, plantingMembers),
          block: copyObjectFields(block, blockMemebers),
        });
      });
    });
  });
  // console.log('collection',retVal);
  return retVal;
}
export function getRecord(block, planting, recordType, sk, getFromGlobalState) {
  const blocks = getFromGlobalState('blocks');
  const updatedBlockIndex = blocks.findIndex((i) => i.sk === block);
  if (updatedBlockIndex === -1) {
    return { status: false, error: 'Block not found', record: undefined };
  }
  // find the planting
  const { plantings } = blocks[updatedBlockIndex];
  const updatedPlantingIndex = blocks[updatedBlockIndex].plantings.findIndex((i) => i.sk === planting);
  if (updatedPlantingIndex === -1) {
    return { status: false, error: 'Planting not found', record: undefined };
  }

  // see if we have the record
  const records = plantings[updatedPlantingIndex][recordType];
  const recordIndex = (records ? plantings[updatedPlantingIndex][recordType].findIndex((i) => i.sk === sk) : -1);

  if (recordIndex === -1) {
    return { status: false, error: 'Record not found', record: undefined };
  }
  return { status: true, record: plantings[updatedPlantingIndex][recordType][recordIndex], error: '' };
}
export function calculateApplicationTotal(block, planting, recordType, applicationType, sk, getFromGlobalState) {
  const record = getRecord(block, planting, recordType, sk, getFromGlobalState);
  if (!record.status) {
    return record;
  }
  // if (record.record[applicationType]) {
  let total = 0;
  const inventoryItems = getFromGlobalState(applicationType);
  // let rowFilter = rows.map(r => { return r.activity; });
  const entries = inventoryItems && inventoryItems.filter((r) => r.sk.startsWith(sk));
  // const entries = inventoryItems.filter((r) => record.record[applicationType].includes(r.sk));
  total = entries && entries.reduce((accumulator, row) => accumulator + parseFloat(row.amount), 0);
  return { status: true, value: total, error: '' };
  // }
  // return { status: true, value: 0, error: '' };
}
export function getBlockCollectionByNameFlat(blocks, crops, products, collectionName, type, inventoryName) {
  const retVal = [];
  if (!blocks) { return []; }
  blocks.forEach((block) => {
    block.plantings && block.plantings.forEach((planting) => {
      // console.log('collection', collectionName);
      if (planting[collectionName]) {
        for (const coll of planting[collectionName]) {
          // planting[collectionName] && planting[collectionName].forEach(function (coll) {
          if (coll.completed !== undefined && coll.completed === true) {
            continue;
          }

          retVal.push({
            blockNumber: block.number,
            blockSK: block.sk,
            plantingSK: planting.sk,
            type,
            inventoryName,
            cropName: getNameFromKey2(planting.crop, crops),
            varietyName: getNameFromKey2(planting.variety, crops.find((c) => c.sk === planting.crop).varieties),
            date: coll.date,
            productName: getNameFromKey2(coll.product, products),
            product: coll.product,
            activitySK: coll.sk,
            recordType: collectionName,
            amount: coll.calculatedProduct,
            // applicationTotal: applicationTotal,
            unit: coll.productUnit,
          });
        }
      }
    });
  });
  // console.log('collection',retVal);
  return retVal;
}

export function getBlockCollection(blocks, crops, irrigations, farm) {
  const retVal = [];
  const locale = getLocale(farm);
  let totalSize = 0;
  let totalPlants = 0;
  let totalBlockSize = 0;
  if (!blocks || !crops || !irrigations) { return []; }
  blocks.forEach((block) => {
    block.plantings && block.plantings.forEach((planting) => {
      totalSize += Number(planting.size);
      totalPlants += Number(planting.totalPlants);
      totalBlockSize += Number(block.size);
      const coordinates = `${calculateMidpoint(block.paths)?.lat?.toFixed(2)}, ${calculateMidpoint(block.paths)?.lng?.toFixed(2)}`;
      retVal.push({
        country:farm?.country,
        province:farm?.province,
        farmName:farm?.name || farm?.shortName,
        plantingName: planting?.name.replace(/_/g," "),
        blockNumber: block?.number,
        blockSK: block?.sk,
        plantingSK: planting?.sk,
        slope: block?.slope,
        soilType: block?.soilType,
        cropName: getNameFromKey2(planting?.crop, crops),
        varietyName: getNameFromKey2(planting?.variety, crops.find((c) => c.sk === planting?.crop)?.varieties, 'cultivar'),
        plantingDate: planting?.date,
        plantSpacing: planting?.plantSpacing,
        rowSpacing: planting?.plantDensity,
        blockSize: Number(block?.size).toLocaleString(locale),
        plantingSize: planting?.size.toLocaleString(locale),
        totalPlants: Number(planting?.totalPlants).toLocaleString(locale),
        irrigationType: block?.irrigationSystem,
        irrigationFlowRate: block?.irrigated === 'irrigated' ? block.volumePerHour.toLocaleString(locale) : '-',
        irrigationFlowRateUnit: block?.flowunit,
        coordinates
      });
    });
  });

  retVal.push({
    strong: true,
    blockNumber: 'Total',
    blockSize: `${totalBlockSize.toLocaleString(locale)} ha`,
    plantingSize: `${totalSize.toLocaleString(locale)} ha`,
    totalPlants: totalPlants.toLocaleString(locale),
  });

  return { retVal };
}

export function getPests(blocks, pests, selectedPest, crops) {
  let retVal = [];
  if (!blocks) { return []; }
  blocks.forEach((block) => {
    block.plantings && block.plantings.forEach((planting) => {
      // console.log('collection', collectionName);
      if (planting.pestings) {
        for (const coll of planting.pestings) {
          if (coll.pest !== selectedPest) {
            continue;
          }

          retVal.push({
            blockNumber: block.number,
            blockSK: block.sk,
            plantingSK: planting.sk,
            pestName: getNameFromKey2(coll.pest, pests),
            cropName: getNameFromKey2(planting.crop, crops),
            varietyName: getNameFromKey2(planting.variety, crops.find((c) => c.sk === planting.crop).varieties),
            pestCount: coll.units,
            date: new Date(coll.date).getTime(),
          });
        }
      }
    });
  });
  // console.log('collection',retVal);
  retVal = retVal.sort((a, b) => b.date - a.date);
  return retVal;
}

function calculateFertDosage(method, volume, planting) {
  let dosageAct = 0;
  switch (method) {
    case 'handPlant': {
      dosageAct = volume ? (volume / planting.totalPlants) : 0;
      break;
    }
    case 'handHa': {
      dosageAct = volume ? (volume / planting.size) : 0;
      break;
    }
    case 'fertilizerSpreader': {
      dosageAct = volume ? (volume / planting.size) : 0;
      dosageAct = `${dosageAct}  /  ha`;
      break;
    }
    case 'fertigation': {
      dosageAct = volume ? (volume / planting.size) : 0;
      dosageAct = `${dosageAct}  /  ha`;
      break;
    }
    case 'drench': {
      dosageAct = volume ? (volume / planting.size) : 0;
      dosageAct = `${dosageAct}  /  tree`;
      break;
    }
    case 'foliarSpray': {
      dosageAct = volume ? (volume / planting.size) : 0;
      dosageAct = `${dosageAct}  /  ha`;
      break;
    }
    case 'arealSpray': {
      dosageAct = volume ? (volume / planting.size) : 0;
      dosageAct = `${dosageAct}  /  ha`;
      break;
    }
    default: {
      dosageAct = 0.0;
      // return;
    }
  }
  return dosageAct;
}

export function getDataByRecordType(recordType, blocks, getFromGlobalState, clean, showAll, RECENTS_LEN, startDate, endDate, excludeTotals, includeUser) {
  // over all the blocks, over all the plantings get records of this type - cal get data and
  // augment the block and planting information.
  let runningTotal = 0;
  let totalRow = {};
  const spacer = {
    blockNumber: ' ',
    litersApplied: ' ',
    date: ' ',
    duration: ' ',
    volumePerHour: ' ',
    flowunit: ' ',
    operator: ' ',
    plantingLabel: ' ',
  };
  const locale = getLocale(getFromGlobalState('farm')[0]);
  const retVal = {
    rows: [], headCells: [], title: '', mostRecent: [],
  };

  if (!blocks) { return retVal; }
  // console.log('entering batch records waterings');
  const t0 = performance.now();
  blocks = blocks && blocks.sort((a, b) => (`${a.number}`).localeCompare(b.number, undefined, { numeric: true }));
  const crops = getFromGlobalState('crops');
  if (!crops) { return retVal; }
  blocks.forEach((block) => {
    block.plantings && block.plantings.forEach((planting) => {
      if (planting[recordType] && planting[recordType].length > 0) {
      //  for (const coll of planting[recordType]) {

        const data = getData(recordType, planting, block, getFromGlobalState, clean, showAll, RECENTS_LEN, true, crops, startDate, endDate, undefined, true, includeUser);
        if (!excludeTotals && recordType === 'waterings' && block.irrigated === 'irrigated') {
          const total = data.rows.reduce((prev, cur) => prev + cur.actualLiters, 0);
          runningTotal += total;
          totalRow = {
            strong: true, blockNumber: 'Total', litersApplied: `${(total / 1000).toLocaleString(locale)} kL`, date: '', duration: '', volumePerHour: '', flowunit: '', operator: '', plantingLabel: '',
          };
        }

        if (!excludeTotals && recordType === 'harvestings') {
          const total = data.rows.reduce((prev, cur) => prev + cur.harvestTotalNoUnit, 0);
          runningTotal += total;
        }

        retVal.rows = retVal.rows.concat(data.rows);
        if (!excludeTotals && recordType === 'waterings' && block.irrigated === 'irrigated') {
          retVal.rows.push(totalRow);
          retVal.rows.push(spacer);
        }
        retVal.headCells = data.headCells;
        retVal.title = data.title;

        // retVal.mostRecent=data.mostRecent;
      //  }
      }
    });
  });
  if (!excludeTotals && recordType === 'waterings') {
    retVal.rows.push({
      strong: true, blockNumber: 'Grand Total', litersApplied: `${(runningTotal / 1000).toLocaleString(locale)} kL`, date: '', duration: '', volumePerHour: '', flowunit: '', operator: '', plantingLabel: '',
    });
  }
  if (!excludeTotals && recordType === 'harvestings') {
    // retVal.rows.push(spacer);
    retVal.rows.push({
      strong: true, blockNumber: 'Grand Total', harvestTotal: `${(runningTotal / 1000).toLocaleString(locale)} t`, date: '', duration: '', volumePerHour: '', flowunit: '', operator: '', plantingLabel: '',
    });
  }

  if (!excludeTotals && recordType === 'fertilisings') {
    // retVal.rows.push(spacer);
    const totals = retVal.rows.filter((f) => f.blockNumber !== 'Total');
    const totalLiquid = totals.reduce((prev, cur) => prev + Number(cur.totalLiquid), 0);
    const totalSolid = totals.reduce((prev, cur) => prev + Number(cur.totalSolid), 0);
    const nPerHa = totals.reduce((prev, cur) => prev + Number(cur.nPerHa), 0);
    const pPerHa = totals.reduce((prev, cur) => prev + Number(cur.pPerHa), 0);
    const kPerHa = totals.reduce((prev, cur) => prev + Number(cur.kPerHa), 0);
    retVal.rows.push({
      strong: true, blockNumber: 'Grand Total', numeric: true, totalLiquid, totalSolid, nPerHa, pPerHa, kPerHa,
    });
  }
  // retVal.rows = retVal.rows && retVal.rows.sort((a, b) => new Date(b.date) - new Date(a.date));
  const t1 = performance.now();
  console.log(`exiting batchrecords ${t1 - t0} milliseconds.`);
  return retVal;
}
function insertBlockDetail(includeBlock, ob, blockNumber, plantingLabel, plantingName, plantingSK, blockSK, cropName, variety) {
  if (includeBlock) {
    ob.plantingLabel = plantingLabel;
    ob.blockNumber = blockNumber;
    ob.plantingSK = plantingSK;
    ob.blockSK = blockSK;
    ob.cropName = cropName;
    ob.varietyName = variety;
    ob.plantingName = plantingName;
  }
}
function spliceBlockHeader(includeBlock, header, position, clean) {
  if (includeBlock) {
    if (clean) {
      const blockHeaderClean = [{
        id: 'blockNumber',
        combine: [{ id: 'blockNumber', opacity: 1 }, { id: 'plantingLabel', opacity: 0.6 }],
        numeric: false,
        disablePadding: false,
        label: 'Block',
      }];
      header.splice(position, 0, ...blockHeaderClean);
    } else {
      const blockHeader = [
        {
          id: 'blockNumber', numeric: false, disablePadding: false, label: 'Block',
        },
        {
          id: 'cropName', numeric: false, disablePadding: false, label: 'Crop',
        },
        {
          id: 'varietyName', numeric: false, disablePadding: false, label: 'Variety',
        },
        {
          id: 'plantingName', numeric: false, disablePadding: false, label: 'Planting',
        }];

      header.splice(position, 0, ...blockHeader);
    }
  }
}
const generateReasonLis = (pests) => {
  if (!pests) {
    return [];
  }
  const rows = pests.map((monitoring) => {
    const ob = {};
    ob.sk = monitoring.sk;
    ob.name = monitoring.targetDetail.name;
    return ob;
  });
  return rows;
};

export function totalPlanting(planting) {
  const totals = {
    fertilisings: { value: 0, unit: '' }, waterings: { value: 0, tasks: 0, unit: '' }, pestings: { value: 0, unit: '' }, sprayings: { value: 0, unit: '' }, harvestings: { value: 0, unit: '' }, packings: { value: 0, unit: '' },
  };

  totals.fertilisings.value = planting && planting.fertilisings ? planting.fertilisings.reduce((accumulator, row) => accumulator + (row.completed === true ? parseFloat(row.volume) : 0), 0) : 0;
  totals.fertilisings.unit = '';

  totals.waterings.value = planting && planting.waterings ? planting.waterings.reduce((accumulator, row) => accumulator + (row.completed ? parseFloat(row.actualLiters) : 0), 0) : 0;
  totals.waterings.unit = 'ℓ';

  totals.waterings.tasks = planting && planting.waterings ? planting.waterings.reduce((accumulator, row) => accumulator + (row.completed ? 0 : 1), 0) : 0;

  totals.pestings.value = planting && planting.pestings ? planting.pestings.reduce((accumulator, row) => accumulator + parseFloat(row.units), 0) : 0;
  totals.pestings.unit = '';

  totals.sprayings.value = planting && planting.sprayings ? planting.sprayings.reduce((accumulator, row) => accumulator + (row.completed === true ? parseFloat(row.volume) : 0), 0) : 0;
  totals.sprayings.unit = 'ℓ';

  totals.harvestings.value = planting && planting.harvestings ? planting.harvestings.reduce((accumulator, row) => accumulator + parseFloat(row.harvestTotal), 0) : 0;
  totals.harvestings.unit = '㎏';

  totals.packings.value = planting && planting.packings ? planting.packings.reduce((accumulator, row) => accumulator + parseFloat(row.packTotal), 0) : 0;
  totals.packings.unit = '㎏';

  return totals;
}

const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export function getThresholdThematic(stageDetail, targetDetail, block, planting, date, observations, pestNum) {
  if (!targetDetail) {
    // alert('LD settings changed');
    console.error('targetDetail does not exist for stageDetail', stageDetail);
    return null;
  }
  const retVal = {
    block: block.number,
    planting: planting.name,
    stageSK: stageDetail.sk,
    stageDescription: stageDetail.stageDescription,
    targetSK: targetDetail.detail.sk,
    targetName: targetDetail.detail.name,
    type: targetDetail.type,
    rag: 'unknown',
    value: null,
    radius: 3,
    cnt: pestNum,
    actualObservations: 0,
    color: 'green',
    date,
    threshold: null,
    thresholdValue: 0,
    recordType: 'Monitoring',
    unit:stageDetail.unit,
    procedure:stageDetail.procedure

  };
  switch (stageDetail.unitNum) {
    case 'Present':
    case 'Number': {
      const requiredObservations = stageDetail.quantityOfUnit;
      // number actualy observed
      const actualObservations = observations.length;
      retVal.actualObservations = actualObservations;

      const sum = observations.reduce((partialSum, a) => partialSum + parseFloat(a), 0);
      const avg = actualObservations === 0 ? 0 : sum / actualObservations;
      // work to required to compare with threshold
      retVal.value = avg;
      if (isNaN(retVal.value)) {
        console.log('Error: isNaN', retVal.value);
      }
      retVal.radius = (pestNum + 1) * 3;
      const avgObserved = avg * (requiredObservations / actualObservations);
      if (stageDetail.threshold === 'Yes') {
        retVal.thresholdValue = avgObserved;

        if (stageDetail.thresholdConstant === 'Yes') {
          const low = parseFloat(stageDetail.thresholdLow);
          const medium = parseFloat(stageDetail.thresholdMedium);
          const high = parseFloat(stageDetail.thresholdHigh);
          retVal.threshold = { low, med: medium, high };
          if (avgObserved < low) {
            retVal.rag = 'low';
            retVal.color = 'green';
          } else if (avgObserved >= low && avgObserved < medium) {
            retVal.rag = 'low';
            retVal.color = 'green';
          } else if (avgObserved >= medium && avgObserved < high) {
            retVal.rag = 'medium';
            retVal.color = 'orange';
          } else if (avgObserved >= high) {
            retVal.rag = 'high';
            retVal.color = 'red';
          } else {
            retVal.rag = 'unknown';
            retVal.color = 'black';
          }
        } else {
          const month = months[new Date(date).getMonth()];
          const threshold = stageDetail.variableThreshold && stageDetail.variableThreshold[month] ? stageDetail.variableThreshold[month] : { low: 0, med: 0, high: 0 };
          retVal.threshold = threshold;
          // number of observation points required at the location

          // retVal.radius *= (pestNum + 1);
          const low = parseFloat(threshold ? threshold.low : 0);
          const medium = parseFloat(threshold ? threshold.med : 0);
          const high = parseFloat(threshold ? threshold.high : 0);

          if (avgObserved < low) {
            retVal.rag = 'low';
            retVal.color = 'green';
          } else if (avgObserved >= low && avgObserved < medium) {
            retVal.rag = 'low';
            retVal.color = 'green';
          } else if (avgObserved >= medium && avgObserved < high) {
            retVal.rag = 'medium';
            retVal.color = 'orange';
          } else if (avgObserved >= high) {
            retVal.rag = 'high';
            retVal.color = 'red';
          } else {
            retVal.rag = 'unknown';
            retVal.color = 'black';
          }
        }
      } else {
        // no thresholds
        retVal.color = 'black';
      }
      break;
    }
    case 'Scale': {
      const actualObservations = observations.length;
      retVal.actualObservations = actualObservations;
      const sum = observations.reduce((partialSum, a) => partialSum + a, 0);
      const avg = actualObservations === 0 ? 0 : sum / actualObservations;
      // work to required to compare with threshold
      retVal.value = avg;
      retVal.color = 'black';
      retVal.radius = (pestNum + 1) * 3;
      if (stageDetail.threshold === 'Yes') {
        if (stageDetail.thresholdConstant === 'Yes') {
          // number of observation points required at the location
          const requiredObservations = stageDetail.quantityOfUnit;
          const avgObserved = avg * (requiredObservations / actualObservations);
          retVal.thresholdValue = avgObserved;
          const low = parseFloat(stageDetail.thresholdLow);
          const medium = parseFloat(stageDetail.thresholdMedium);
          const high = parseFloat(stageDetail.thresholdHigh);
          retVal.threshold = { low, med: medium, high };

          if (avgObserved < low) {
            retVal.rag = 'low';
            retVal.color = 'green';
          } else if (avgObserved >= low && avgObserved < medium) {
            retVal.rag = 'low';
            retVal.color = 'green';
          } else if (avgObserved >= medium && avgObserved < high) {
            retVal.rag = 'medium';
            retVal.color = 'orange';
          } else if (avgObserved >= high) {
            retVal.rag = 'high';
            retVal.color = 'red';
          } else {
            retVal.rag = 'unknown';
            retVal.color = 'black';
          }
        } else {
          // variable threshold
        }
      } else {
        // no thresholds
        // number of observation points required at the location
        // const requiredObservations = stageDetail.quantityOfUnit;

      }

      break;
    }
    default: {
      // dosageAct = 0.0;
      // return;
    }
  }

  return retVal;
}

function distinctPush(array, member) {
  const idx = array.findIndex((p) => p === member);
  if (idx === -1) {
    array.push(member);
  }
}

export function generateTargetsRows(monitorings, getFromGlobalState) {
  if (!monitorings) {
    return [];
  }
  const targetTypes = getFromGlobalState('targetsTypesSetting');
  const rows = monitorings.map((monitoring) => {
    const ob = {};
    ob.sk = monitoring.sk;
    ob.targetType = getNameFromKey2(monitoring.targetType, targetTypes, 'name');
    ob.name = monitoring.targetDetail && monitoring.targetDetail.name;
    ob.cropSpecific = monitoring.targetDetail && monitoring.targetDetail.cropSpecific;
    ob.observableOn = '';
    ob.stages = '';
    ob.isDisabled = monitoring.isDisabled ? 'Yes' : 'No';
    ob.mobileDescription = 'Not crop specific';
    ob.targetSK = monitoring.targetDetail.sk;

    if (monitoring.cropSpecific === 'Yes') {
      ob.observableOn = monitoring.targetDetail && monitoring.targetDetail.observableOn[0].name;
      // ob.targetSK = monitoring.targetDetail.sk;
      ob.observableOnSk = monitoring.targetDetail && monitoring.targetDetail.observableOn[0].sk;
      const selectedStages = monitoring.targetDetail.observableOn[0].stages && monitoring.targetDetail.observableOn[0].stages.filter((p) => p.selected === true);
      ob.stages = monitoring.targetDetail && selectedStages ? selectedStages.length : 0;
      ob.mobileDescription = `Crop specific,  Stages: ${ob.stages} `;
      ob.stagesDescrip = '';
      for (let sc = 0; sc < ob.stages; sc += 1) {
        ob.stagesDescrip = monitoring.targetDetail.observableOn[0].stages[sc].stageDescription && ob.stagesDescrip.concat(monitoring.targetDetail.observableOn[0].stages[sc].stageDescription, ', ');
      }
    }
    return ob;
  });
  return rows;
}
export function generateTargetsRowsByType(rows, getFromGlobalState) {
  if (!rows) {
    return null;
  }
  const targets = {};
  const targetTypes = getFromGlobalState('targetsTypesSetting');
  for (let i = 0; i < targetTypes.length; i += 1) {
    const type = targetTypes[i].name;
    const typeRows = rows.filter((f) => f.targetType === type);
    if (typeRows && typeRows.length) {
      targets[type] = typeRows;
    }
  }
  return targets;
}

export function generateTargetsRowsByTypeArr(rows, getFromGlobalState) {
  if (!rows) {
    return null;
  }
  const targets = [];
  const targetTypes = getFromGlobalState('targetsTypesSetting');
  for (let i = 0; i < targetTypes.length; i += 1) {
    const type = targetTypes[i].name;
    const { sk } = targetTypes[i];
    const targetsList = rows.filter((f) => f.targetType === type);
    if (targetsList && targetsList.length) {
      // targets[type] = typeRows;
      targets.push({ name: type, sk, targetsList });
    }
  }
  return targets;
};


function getWeekOfYear(date) {
  const startDate = new Date(date.getFullYear(), 0, 1);
  const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
  const weekNumber = Math.ceil((days + 1) / 7);
  return weekNumber;
}

export const getHarvestingData = (blks)=> {
  const cropDataMap = new Map();

  const harvest = blks.flatMap((x)=>x.plantings);

  harvest.forEach(block => {
      block.harvestings.forEach(harvesting => {
          const harvestDate = new Date(harvesting.date);
          const year = harvestDate.getFullYear();
          const weekNumber = getWeekOfYear(harvestDate);
          const yearWeek = `${year}_${weekNumber}`;

          const key = `${block.name}-${yearWeek}`;

          if (!cropDataMap.has(block.name)) {
              cropDataMap.set(block.name, []);
          }

          const cropData = cropDataMap.get(block.name);
          const existingData = cropData.find(data => data.week === yearWeek);

          if (existingData) {
              existingData.totalKgs +=  parseInt(harvesting.harvestTotal);
          } else {
              cropData.push({
                  crop: block.name,
                  totalKgs: harvesting.harvestTotal,
                  year: year,
                  week: yearWeek,
                  yearWeek: yearWeek,
                  weekNumber: weekNumber

              });
          }
      });
  });

  // Convert Map to array
  const data = [];
  cropDataMap.forEach((value, key) => {
      data.push(...value);
  });

  // Group data by week for each crop
  const result = [];
  cropDataMap.forEach((value, key) => {
      value.forEach(item => {
          const existingEntry = result.find(entry => entry.week === item.week);
          if (existingEntry) {
              existingEntry[`totalKgs_${key}`] = item.totalKgs;
          } else {
              const newEntry = { week: item.week, [`totalKgs_${key}`]: item.totalKgs };
              newEntry.year = item.year;
              newEntry.weekNumber = item.weekNumber;
              result.push(newEntry);
          }
      });
  });

  return result.sort((a, b) => {
    if (a.year !== b.year) {
        return a.year - b.year; // Sort by year first
    } else {
        return a.weekNumber - b.weekNumber; // Within the same year, sort by weekNumber
    }
});;
}



// export const getIrrigigationData=(data)=>  {
//   const waterings = data.flatMap(entry => entry.plantings.flatMap(planting => planting.waterings));
  
//   const litersByWeek = {};

  
//   Date.prototype.getWeek = function() {
//     var date = new Date(this.getTime());
//     date.setHours(0, 0, 0, 0);
//     // Thursday in current week decides the year.
//     date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
//     // January 4 is always in week 1.
//     var week1 = new Date(date.getFullYear(), 0, 4);
//     // Adjust to Thursday in week 1 and count number of weeks from date to week1.
//     return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
//   }

//   waterings.forEach(watering => {
//       const date = new Date(watering.date);
//       date.setHours(0, 0, 0, 0); // Reset the time to avoid DST issues
//       const weekStart = new Date(date);
//       weekStart.setDate(weekStart.getDate() - weekStart.getDay()); // assuming Sunday as start of week
//       const weekEnd = new Date(weekStart);
//       weekEnd.setDate(weekEnd.getDate() + 6);

//       const weekKeyNum = ('0' + (weekStart.getWeek() + 1)).slice(-2);
//       const weekKey = `Week(${weekKeyNum}) ${weekStart.getFullYear()}, ${weekStart.getMonth() + 1}/${weekStart.getDate()} to ${weekEnd.getMonth() + 1}/${weekEnd.getDate()}`;
//       if (!litersByWeek[weekKey]) {
//           litersByWeek[weekKey] = {
//               liters: 0,
//               name: weekStart.getFullYear()+"_"+weekKeyNum,
//               year:weekStart.getFullYear(),
//               weekNumber:weekKeyNum,
//               dateRange: `${weekStart.toLocaleDateString()} - ${weekEnd.toLocaleDateString()}`
//           };
//       }
//       litersByWeek[weekKey].liters += watering.actualLiters;
//   });

//   const returnedData = Object.values(litersByWeek);


//   return returnedData.sort((a, b) => {
//     if (a.year !== b.year) {
//         return a.year - b.year; // Sort by year first
//     } else {
//         return a.weekNumber - b.weekNumber; // Within the same year, sort by weekNumber
//     }
// });
// }

export const getIrrigationData = (datas) => {
  const plantDataMap = new Map();

  const waterings = datas.flatMap(entry => entry.plantings);

  waterings.forEach(planting => {
      planting.waterings.forEach(watering => {
          const wateringDate = new Date(watering.date);
          const year = wateringDate.getFullYear();
          const weekNumber = getWeekOfYear(wateringDate);
          const yearWeek = `${year}_${weekNumber}`;
          const key = `${planting.name}-${yearWeek}`;

          if (!plantDataMap.has(planting.name)) {
              plantDataMap.set(planting.name, []);
          };

          const plantData = plantDataMap.get(planting.name);
          const existingData = plantData.find(data => data.week === yearWeek);

          if (existingData) {
              existingData.totalLiters += parseInt(watering.actualLiters);
          } else {
              plantData.push({
                  plant: planting.plant,
                  totalLiters: watering.actualLiters,
                  year: year,
                  week: yearWeek,
                  yearWeek: yearWeek,
                  weekNumber: weekNumber
              });
          }
      });
  });

  // Convert Map to array
  const data = [];
  plantDataMap.forEach((value, key) => {
      data.push(...value);
  });

  // Group data by week for each plant
  const result = [];
  plantDataMap.forEach((value, key) => {
      value.forEach(item => {
          const existingEntry = result.find(entry => entry.week === item.week);
          if (existingEntry) {
              existingEntry[`totalLiters_${key}`] = item.totalLiters;
          } else {
              const newEntry = { week: item.week, [`totalLiters_${key}`]: item.totalLiters };
              newEntry.year = item.year;
              newEntry.weekNumber = item.weekNumber;
              result.push(newEntry);
          }
      });
  });

  return result.sort((a, b) => {
    if (a.year !== b.year) {
        return a.year - b.year; // Sort by year first
    } else {
        return a.weekNumber - b.weekNumber; // Within the same year, sort by weekNumber
    }
  });
}

export function getFertiliserCircles(blocks, fertiliserSettings) {
  const fertilserDetail = {};
  const distinctFertilisers = [];
  const retVal = { circles: [] };
  const totals = {
    liters: 0.0, kg: 0.0, n: 0.0, p: 0.0, k: 0.0, ca: 0.0, mg: 0.0, s: 0.0, zn: 0.0, b: 0.0, fe: 0.0, mn: 0.0, cu: 0.0, mo: 0.0,
  };
  for (let b = 0; blocks && b < blocks.length; b += 1) {
    const block = blocks[b];
    for (let pl = 0; block.plantings && pl < block.plantings.length; pl += 1) {
      const planting = block.plantings[pl];
      for (let f = 0; planting.fertilisings && planting.fertilisings && f < planting.fertilisings.length; f += 1) {
        const fertilising = planting.fertilisings[f];
        if (!fertilserDetail[fertilising.product]) {
          const product = fertiliserSettings.find((s) => s.sk === fertilising.product);
          fertilserDetail[fertilising.product] = fertiliserSettings.find((s) => s.sk === fertilising.product);
          distinctPush(distinctFertilisers, product?.name);
        }
        const fertiliser = fertilserDetail[fertilising.product];
        const ob = {
          nPerHa: 0.0, pPerHa: 0.0, kPerHa: 0.0, caPerHa: 0.0, mgPerHa: 0.0, sPerHa: 0.0, bPerHa: 0.0, fePerHa: 0.0, mnPerHA: 0.0, znPerHa: 0.0, cuPerHa: 0.0, moPerHa: 0.0,
        };
        if (!ob.total) {
          ob.total = fertilising.volume;
        }
        ob.unit = fertiliser?.unit;
        if (fertiliser?.unit === 'liter') {
          ob.totalLiquid =(ob.total/1000).toFixed(2);
          ob.totalSolid = '';
          totals.liters += Number(ob.total);
        } else {
          ob.totalLiquid = '';
          ob.totalSolid = (ob.total/1000).toFixed(2);
          totals.kg += Number(ob.total);
        }

        if (!fertiliser?.totalUnit) {
          ob.totalUnit = '';
        }
        ob.qtyUnit = ob.totalUnit;
        if (ob.totalUnit === 'g') {
          ob.totalUnit = 'kg';
          ob.total /= 1000;
        }
        if (ob.totalUnit === 'ml') {
          ob.totalUnit = 'l';
          ob.total /= 1000;
        }
        if (!fertiliser?.unit) {
          ob.unit = '';
        }
        if (fertiliser?.nitrogenP) {
          const n = fertiliser.nitrogenP ? Number(((ob.total * fertiliser.nitrogenP) / 100 / planting.size)) : 0;
          totals.n += n;
          ob.nPerHa = n;
        }
        if (fertiliser?.phosphorusP) {
          const p = fertiliser.phosphorusP ? Number(((ob.total * fertiliser.phosphorusP) / 100 / planting.size)) : 0;
          totals.p += p;
          ob.pPerHa = p;
        }
        if (fertiliser?.potassiumP) {
          const k = fertiliser.potassiumP ? Number(((ob.total * fertiliser.potassiumP) / 100 / planting.size)) : 0;
          totals.k += k;
          ob.kPerHa = k;
        }
        if (fertiliser?.caP) {
          const k = fertiliser.caP ? Number(((ob.total * fertiliser.caP) / 100 / planting.size)) : 0;
          totals.ca += k;
          ob.caPerHa = k;
        }
        if (fertiliser?.mgP) {
          const k = fertiliser.mgP ? Number(((ob.total * fertiliser.mgP) / 100 / planting.size)) : 0;
          totals.mg += k;
          ob.mgPerHa = k;
        }
        if (fertiliser?.sP) {
          const k = fertiliser.sP ? Number(((ob.total * fertiliser.sP) / 100 / planting.size)) : 0;
          totals.s += k;
          ob.sPerHa = k;
        }
        if (fertiliser?.znP) {
          const k = fertiliser.znP ? Number(((ob.total * fertiliser.znP) / 100 / planting.size)) : 0;
          totals.zn += k;
          ob.znPerHa = k;
        }
        if (fertiliser?.bP) {
          const k = fertiliser.bP ? Number(((ob.total * fertiliser.bP) / 100 / planting.size)) : 0;
          totals.b += k;
          ob.bPerHa = k;
        }
        if (fertiliser?.feP) {
          const k = fertiliser.feP ? Number(((ob.total * fertiliser.feP) / 100 / planting.size)) : 0;
          totals.fe += k;
          ob.fePerHa = k;
        }
        if (fertiliser?.mnP) {
          const k = fertiliser.mnP ? Number(((ob.total * fertiliser.mnP) / 100 / planting.size)) : 0;
          totals.mn += k;
          ob.mnPerHA = k;
        }
        if (fertiliser?.cuP) {
          const k = fertiliser.cuP ? Number(((ob.total * fertiliser.cuP) / 100 / planting.size)) : 0;
          totals.cu += k;
          ob.cuPerHa = k;
        }
        if (fertiliser?.moP) {
          const k = fertiliser.moP ? Number(((ob.total * fertiliser.moP) / 100 / planting.size)) : 0;
          totals.mo += k;
          ob.moPerHa = k;
        }

        ob.block = block?.number;
        ob.planting = planting?.name;
        ob.date = fertilising?.date;
        ob.product = fertiliser?.name;
        ob.productSK = fertiliser?.sk;
        ob.productUnit = fertiliser?.unit;
        retVal.circles.push(ob);
      }
    }
  }
  retVal.distinctFertilisers = distinctFertilisers;
  return retVal;
}

export function getCircles(blocks, montoringsSettings, targetTypes) {
  const stageDetail = {};
  const targetDetails = {};
  const distinctTypes = [];
  const distinctTargets = [];
  const distinctStages = [];
  const retVal = { circles: [], targetTypes: {} };
  for (let b = 0; blocks && b < blocks.length; b += 1) {
    const block = blocks[b];
   

    for (let p = 0; block.plantings && p < block.plantings.length; p += 1) {
      const planting = block.plantings[p];
      // const sprayings = (planting?.sprayings||[]).map((x)=>({
      //   reasons:(x?.reasons||[]).map((x)=>({
      //     applied:x.applied,
      //     target:x.target,
      //     targetType:x.targetType
      //   })),
      //   volume:x.volume,
      //   date:x.date,
      //   product:x.product
      // }))
      const sprayings = planting?.sprayings||[];
      for (let m = 0; planting.pestings && m < planting.pestings.length; m += 1) {
        const pesting = planting.pestings[m];
        // const { geoLocations } = pesting;

        for (let o = 0; pesting && pesting.observations && o < pesting.observations.length; o += 1) {
          // number of pests I scouted
          const observation = pesting.observations[o];
          const stageID = observation.stageSK ? observation.stageSK : observation.targetDetailSK; // non crop specific
          if (observation) {
            if (!stageDetail[stageID]) {
              if (observation.cropSpecific) {
                const setting = montoringsSettings.find((s) => s.sk === observation.monitoringSK);
                if (setting) {
                  stageDetail[stageID] = setting.targetDetail.observableOn[0].stages.find((s) => s.sk === observation.stageSK);
                } else {
                  console.error('setting not found monitoringSK', observation.monitoringSK);
                }
              } else {
                const setting = montoringsSettings.find((s) => s.sk === observation.monitoringSK);
                if (setting) {
                  stageDetail[stageID] = montoringsSettings.find((s) => s.sk === observation.monitoringSK).targetDetail.nonCropDetail;
                } else {
                  console.error('setting not found monitoringSK', observation.monitoringSK);
                }
              }
              if (!stageDetail[stageID]) {
                console.error('stageDetail[stageID] not found StageSK', observation.stageSK, 'targetDetailSK', observation.targetDetailSK);
              }
            } // move bottom brack to here
            if (stageDetail[stageID] && !targetDetails[observation.monitoringSK]) {
              const sett = montoringsSettings.find((ms) => ms.sk === observation.monitoringSK);
              const targetType = sett && sett.targetType;
              const targetDetail = sett && sett.targetDetail;
             // const { targetType, targetDetail } = montoringsSettings && montoringsSettings.find((ms) => ms.sk === observation.monitoringSK);
              if (!targetDetail) {
                // const g = 0;
                console.error('targetDetail not found', observation.monitoringSK);
              }
              targetDetails[observation.monitoringSK] = {};
              const tType = (targetTypes||[]).find((tt) => tt.sk === targetType);
              targetDetails[observation.monitoringSK].type = tType ? tType.name : 'unknown';
              targetDetails[observation.monitoringSK].detail = targetDetail;
              distinctPush(distinctTypes, targetDetails[observation.monitoringSK].type);
              distinctPush(distinctTargets, targetDetails[observation.monitoringSK].detail.name);
              distinctPush(distinctStages, stageDetail[stageID] && stageDetail[stageID].stageDescription ? stageDetail[stageID].stageDescription : '');
            }
            if (!targetDetails[observation.monitoringSK]) {
              console.error('targetDetail not found 2', observation.monitoringSK);
            }
            // } // this is bottom bracket
            for (let t = 0; observation && stageDetail[stageID] && t < observation.entries.length; t += 1) {
              // number of trees I walked
              const entries = observation.entries[t];
              // number of fruit I scouted at the tree
              if (entries && entries.length) {
                const observationData = getThresholdThematic(stageDetail[stageID], targetDetails[observation.monitoringSK], block, planting, pesting.date, entries, o);
                const geoLocation = pesting?.geoLocations && pesting?.geoLocations[t];
                retVal.circles.push({
                  block: block.number,
                  blockSK: block.sk,
                  sk: pesting.sk,
                  planting: planting.name,
                  lat: geoLocation?.latitude,
                  lng: geoLocation?.longitude,
                  observationData,
                  observation,
                  createdByDetail: pesting.createdByDetail,
                  createdAt: pesting.createdAt,
                  targetDetail: targetDetails[observation.monitoringSK],
                  stageDetail: stageDetail[stageID],
                  sprayings,
                  monitoringSK:observation
                });
              }
            }

            // circles[0].push({ lat: geoLocation.latitude, lng: geoLocation.longitude });
          }
        }
      }
    }
  }
  retVal.targetTypes = distinctTypes;
  retVal.targets = distinctTargets;
  retVal.stages = distinctStages;
  return retVal;
}

export function getData(recordType, planting, block, getFromGlobalState, clean, showAll, RECENTS_LEN, includePlantingDetail, crops, startDate, endDate, noSort, batch, includeUser) {
  const data = {
    rows: [], headCells: [], title: '', mostRecent: [], locale: null,
  };

  const includeBlock = includePlantingDetail && crops;
  if (includeBlock) {
    data.blockNumber = block && block.number;
    data.blockSK = block && block.sk;
    data.plantingSK = planting.sk;
    data.cropName = getNameFromKey2(planting.crop, crops);
    data.varietyName = getNameFromKey2(planting?.variety, crops.find((c) => c.sk === planting.crop)?.varieties, 'cultivar');
    data.plantingLabel = `${data.cropName} - ${data.varietyName}`;
    data.plantingName = planting.name;
  }

  const farm = getFromGlobalState('farm')[0];
  const locale = getLocale(farm);
  data.locale = locale;

  switch (recordType) {
    case 'fertilisings': {
      const totals = {
        liters: 0.0, kg: 0.0, n: 0.0, p: 0.0, k: 0.0,
      };
      const combinations = getFromGlobalState('combinations');
      const fertliserCombinations = combinations.filter((x)=>x.combinationType === "Fertiliser")
      const fertilisers = getFromGlobalState('fertilisers').concat(fertliserCombinations);
      let records = planting[recordType] ? planting[recordType] : [];
      if (startDate && endDate) {
        records = records.filter((r) => r.date >= startDate && r.date <= endDate);
      }
      data.rows = records ? records.map((item) => {
        const ob = { ...item };
        ob.productName = getNameFromKey2(item.product, fertilisers);
        if (!clean && includeUser) {
          ob.updatedBy = item.createdByDetail ? `${item.createdByDetail.name} ${item.createdByDetail.surname} ${new Date(parseInt(item.createdAt)).toLocaleString()}` : '';
        }
        ob.isComplete = ob.completed ? 'Yes' : 'No';
        insertBlockDetail(includeBlock, ob, data.blockNumber, data.plantingLabel, data.plantingName, data.plantingSK, data.blockSK, data.cropName, data.varietyName);
        //  ob.startDateTime = `${ob.date} ${ob.time}`;
        ob.endTime = ob.endTime ? ob.endTime : 'Busy';
        console.log(item)
        const fertiliser = fertilisers.find((p) => p.sk === item.product);
          ob.advisor = fertiliser?.advisor
          //  if (fertcrop.totalUnit === 'Plant') {
          ob.nPerHa = 0.0;
          ob.pPerHa = 0.0;
          ob.kPerHa = 0.0;
          if (!item.total) {
            ob.total = item.volume;
          }
          if (item.totalUnit === 'l' || item.totalUnit === 'ml') {
            const totalToAdd = ob.totalUnit === "ml" ? (ob.total/1000).toFixed(2): parseFloat(ob.total).toFixed(2);
            ob.totalLiquid = totalToAdd;
            ob.totalSolid = '';
            totals.liters += Number(totalToAdd);
          } else {
            ob.totalLiquid = '';
            console.log(ob.totalUnit);
            const totalToAdd = ob.totalUnit === "g" ? (ob.total/1000).toFixed(2): parseFloat(ob.total).toFixed(2);
            ob.totalSolid = totalToAdd;
            totals.kg += Number(totalToAdd);
          }

          if (!item.totalUnit) {
            ob.totalUnit = '';
          }
          ob.qtyUnit = ob.totalUnit;
          if (!item.unit) {
            ob.unit = '';
          }
          if (fertiliser?.nitrogenP) {
            const n = Number(((ob.total * fertiliser.nitrogenP) / 100 / planting.size));
            totals.n += n;
            ob.nPerHa = parseFloat(n.toFixed(2));
          }
          if (fertiliser?.phosphorusP) {
            const p = Number(((ob.total * fertiliser.phosphorusP) / 100 / planting.size));
            totals.p += p;
            ob.pPerHa = parseFloat(p.toFixed(2));
          }
          if (fertiliser?.potassiumP) {
            const k = Number(((ob.total * fertiliser.potassiumP) / 100 / planting.size));
            totals.k += k;
            ob.kPerHa = parseFloat(k.toFixed(2));
          }
          ob.volume = item.volume ? `${item.volume.toLocaleString(locale)} ${ob.qtyUnit} / ${ob.unit}` : '0';
          ob.totalUnit = `${ob.total && locale && ob.total?.toLocaleString(locale)} ${ob.totalUnit} ${item.totalOverride ? ' (override)' : ''}`; // 'kg per Plant';
          ob.calculatedVolumeDisplay = '';
          // ob.appMethod = fertcrop.application.methodText;
          const operator = getObjectFromKey('operators', item.operator, getFromGlobalState);
          ob.operator = operator ? `${operator.name} ${operator.surname}` : '';
          //  } else {
          // ob.displayUnit = 'kg per 100L';
          // ob.displayValue = fertcrop.valper100l;
          // ob.calculatedVolumeDisplay = ob.calculatedVolume;
          //   }

        // get the unit for this fertcrop

        return ob;
      }) : [];
      // sort rows
      if (!noSort) {
        data.rows = data.rows && data.rows.sort((a, b) => new Date(b.date) - new Date(a.date));
      }
      data.mostRecent = data.rows && data.rows.slice(0, RECENTS_LEN);
      // let unit = data.rows[0] ? data.rows[0].productUnit: '';
      if (clean) {
        data.headCells = [
          // combine:[{id:'duration',opacity:1,postfix:'hour'}]
          {
            id: 'date',
            combine: [
              { id: 'date', opacity: 1 },
              { id: 'productName', opacity: 0.6 },
            ],
            numeric: false,
            disablePadding: false,
            label: 'Date',
          },

          {
            id: 'totalUnit', combine: [{ id: 'totalUnit', opacity: 1 }], numeric: true, disablePadding: false, label: 'Total',
          },

        ];
      } else {
        data.headCells = [

          {
            id: 'date', numeric: false, disablePadding: false, label: 'Date',
          },
          {
            id: 'productName', numeric: false, disablePadding: false, label: 'Product',
          },
          {
            id: 'totalLiquid', numeric: true, disablePadding: false, label: 'Total L',
          },
          {
            id: 'totalSolid', numeric: true, disablePadding: false, label: 'Total kg',
          },
          {
            id: 'volume', numeric: true, disablePadding: false, label: 'Applied QTY',
          },
          {
            id: 'nPerHa', numeric: true, disablePadding: false, label: '(N) kg/ha',
          },
          {
            id: 'pPerHa', numeric: true, disablePadding: false, label: '(P) kg/ha',
          },
          {
            id: 'kPerHa', numeric: true, disablePadding: false, label: '(K) kg/ha',
          },

          /* {
            id: 'displayValueAct', numeric: true, disablePadding: false, label: 'Actual Dosage',
          }, */
          /* {
            id: 'displayUnit', numeric: false, disablePadding: false, label: 'Unit',
          }, */
          /* {
            id: 'appMethod', numeric: true, disablePadding: false, label: 'Application method',
          }, */
          {
            id: 'operator', numeric: true, disablePadding: false, label: 'Operator',
          },
          {
            id: 'advisor', numeric: true, disablePadding: false, label: 'Advisor',
          }
        ];
      }
      if (!clean && includeUser) {
        data.headCells.push({
          id: 'updatedBy', numeric: false, disablePadding: false, fontSize: '9px', label: 'Updated by',
        });
      }
      if (!clean) {
        if (!batch) {
          const totalRow = {
            strong: true,
            date: 'Total',
            numeric: true,
            totalLiquid: (totals.liters).toFixed(2),
            totalSolid: (totals.kg).toFixed(2),
            nPerHa: totals.n,
            pPerHa: totals.p,
            kPerHa: totals.k,
          };
          data.rows.push(totalRow);
        } else {
          const totalRow = {
            strong: true,
            blockNumber: 'Total',
            numeric: true,
            totalLiquid: (totals.liters).toFixed(2),
            totalSolid: (totals.kg).toFixed(2),
            nPerHa: parseFloat(totals.n.toFixed(2)),
            pPerHa: parseFloat(totals.p.toFixed(2)),
            kPerHa: parseFloat(totals.k.toFixed(2)),
          };
          data.rows.push(totalRow);
        }
      }
      spliceBlockHeader(includeBlock, data.headCells, 0, clean);
      data.title = 'Fertiliser Records';
      return data;
    }
    case 'waterings': {
      let records = planting[recordType] ? planting[recordType] : [];
      if (startDate && endDate) {
        records = records.filter((r) => r.date >= startDate && r.date <= endDate);
      }
      data.rows = records ? records.map((item) => {
        const ob = { ...item };
        ob.duration = ob.duration.toLocaleString(locale);
        if (!block.volumePerHour) {
          block.volumePerHour = 0;
          ob.actualLiters = 0;
        }
        ob.volumePerHour = block.volumePerHour.toLocaleString(locale);
        ob.flowunit = block.flowunit;
        if (!clean && includeUser) {
          ob.updatedBy = item.createdByDetail ? `${item.createdByDetail.name} ${item.createdByDetail.surname} ${new Date(parseInt(item.createdAt)).toLocaleString()}` : '';
        }
        // const createdByUser =getObjectFromKey('users',item.createdBy, getFromGlobalState);
        ob.litersApplied = `${ob.actualLiters?.toLocaleString(locale)} ℓ`;
        insertBlockDetail(includeBlock, ob, data.blockNumber, data.plantingLabel, data.plantingName, data.plantingSK, data.blockSK, data.cropName, data.varietyName);

        const operator = getObjectFromKey('operators', item.operator, getFromGlobalState);
        ob.operator = operator ? `${operator.name} ${operator.surname}` : '';

        return ob;
      }) : [];

      // sort rows
      if (!noSort) {
        data.rows = data.rows && data.rows.sort((a, b) => new Date(b.date) - new Date(a.date));
      }
      data.mostRecent = data.rows && data.rows.slice(0, RECENTS_LEN);
      const total = 0;

      //  total =  data.rows.reduce(function(prev, cur) {
      //    return prev + cur.actualLiters;
      //  }, 0);
      //  data.rows.push({strong:true,blockNumber: 'Total',litersApplied: `${(total/1000).toLocaleString(locale)} kl`,date:'Total',duration:'',volumePerHour:'',flowunit:'',operator:'',plantingLabel:''});

      if (!batch) {
        const totalWater = data.rows.reduce((prev, cur) => prev + cur.actualLiters, 0);
        const totalLocale = `${(totalWater / 1000).toLocaleString(locale)} kl`;
        // const totalRow = { strong:true,blockNumber: 'Total',litersApplied: `${(total/1000).toLocaleString(locale)} kL`,date:'',duration:'',volumePerHour:'',flowunit:'',operator:'',plantingLabel:''};
        const totalRow = {
          strong: true, date: 'Total', duration: '', volumePerHour: '', litersApplied: totalLocale, operator: '',
        };
        data.rows.push(totalRow);
      }

      if (clean) {
        data.headCells = [
          {
            id: 'date', combine: [{ id: 'date', opacity: 1 }, { id: 'duration', opacity: 0.6, postfix: 'hour' }, { id: 'volumePerHour', opacity: 0.6, postfix: 'l/h' }], numeric: false, disablePadding: false, label: 'Date',
          },
          {
            id: 'litersApplied', combine: [{ id: 'litersApplied', opacity: 1 }], numeric: true, disablePadding: false, label: '(liter)',
          },

        ];
      } else {
        data.headCells = [
          {
            id: 'date', numeric: false, disablePadding: false, label: 'Date', minWidth: '80px',
          },
          {
            id: 'duration', numeric: true, disablePadding: false, label: 'Duration open (hr)',
          },
          {
            id: 'volumePerHour', numeric: true, disablePadding: false, label: 'Flow rate (L/hr)',
          },
          /* {
            id: 'flowunit', numeric: false, disablePadding: false, label: 'Flow Unit',
          }, */
          {
            id: 'litersApplied', combine: [{ id: 'litersApplied', opacity: 1 }], numeric: true, disablePadding: false, label: 'Total water (L)',
          },
          {
            id: 'operator', numeric: false, disablePadding: false, label: 'Operator',
          },
        ];
      }
      if (!clean && includeUser) {
        data.headCells.push({
          id: 'updatedBy', numeric: false, disablePadding: false, fontSize: '9px', label: 'Updated by',
        });
      }

      data.title = 'Irrigation Records';
      data.total = total;
      spliceBlockHeader(includeBlock, data.headCells, 0, clean);
      return data;
    }
    case 'pestings': {
      // const operators = getFromGlobalState('operators');
      const monitoringSettings = getFromGlobalState('monitorings');
      const targetsTypesSetting = getFromGlobalState('targetsTypesSetting');
      const tmpBlock = { ...block };
      tmpBlock.plantings = [planting];
      const monitoringData = getCircles([tmpBlock], monitoringSettings, targetsTypesSetting);
      if (startDate && endDate) {
        monitoringData.circles = monitoringData.circles ? monitoringData.circles.filter((r) => r.observationData.date >= startDate && r.observationData.date <= endDate) : [];
      }
      data.rows = monitoringData.circles ? monitoringData.circles.map((item) => {
        const ob = {};
        ob.date = item.observationData.date;
        ob.capturedDateTime = new Date(parseInt(item.createdAt)).toLocaleString();
        ob.sk = item.sk;
        ob.blockSK = item.blockSK;
        ob.targetType = item.observationData.type;
        ob.name = item.observationData.targetName;
        ob.stageDescription = item.observationData.stageDescription;
        ob.actualObservations = item.observationData.actualObservations;
        ob.value = item.observationData.value;
        ob.rag = item.observationData.threshold ? item.observationData.rag : 'none';
        const avatar = getAvatar(item.observationData.color);
        ob.ragIcon = avatar.icon;
        ob.ragOrder = avatar.ragOrder;
        ob.thresholdValue = item.observationData.thresholdValue;
        ob.threshold = item.observationData.threshold ? `Low: ${item.observationData.threshold.low} Medium: ${item.observationData.threshold.med} High: ${item.observationData.threshold.high}` : '';
        ob.cropSpecific = item.targetDetail.detail.cropSpecific;

        if (!clean && includeUser) {
          ob.updatedBy = item.createdByDetail ? `${item.createdByDetail.name} ${item.createdByDetail.surname} ${new Date(parseInt(item.createdAt)).toLocaleString()}` : '';
        }
        const operator = item.createdByDetail;
        ob.operator = operator ? `${operator.name} ${operator.surname}` : '';
        insertBlockDetail(includeBlock, ob, data.blockNumber, data.plantingLabel, data.plantingName, data.plantingSK, data.blockSK, data.cropName, data.varietyName);
        return ob;
      }) : [];
      data.mostRecent = data.rows && sortOutAverages(data.rows)?.slice(0, RECENTS_LEN);
      if (clean) {
        data.headCells = [
          {
            id: 'ragIcon', numeric: false, disablePadding: false, label: '', type: 'icon',
          },
          {
            id: 'date',
            combine: [
              { id: 'date', opacity: 1 },
              { id: 'targetType', opacity: 1 },
              { id: 'name', opacity: 0.6 },
              { id: 'stageDescription', opacity: 0.4 },
            ],
            numeric: false,
            disablePadding: false,
            label: 'Target',
          },
        ];
       data.rows = data.rows;
      } else {
        data.headCells = [
          {
            id: 'ragIcon', numeric: false, disablePadding: false, label: '', type: 'icon',
          },
          {
            id: 'rag', numeric: false, disablePadding: false, label: 'Threshold status',
          },
          {
            id: 'date', numeric: false, disablePadding: false, label: 'Date', minWidth: '80px',
          },
          {
            id: 'capturedDateTime', numeric: false, disablePadding: false, label: 'Captured at', minWidth: '80px',
          },
          {
            id: 'targetType', numeric: false, disablePadding: false, label: 'Target Type',
          },
          {
            id: 'name', numeric: true, disablePadding: false, label: 'Name',
          },
          {
            id: 'stageDescription', numeric: true, disablePadding: false, label: 'Stage Description',
          },
          {
            id: 'cropSpecific', numeric: true, disablePadding: false, label: 'Crop Specific',
          },
         {
            id: 'value', numeric: false, disablePadding: false, label: 'Sum Of Observations',
          },
          {
            id: 'observationCount', numeric: false, disablePadding: false, label: 'Number Of Observations',
          },
          {
            id: 'average', numeric: false, disablePadding: false, label: '% Incidence or Average',
          },
          {
            id: 'threshold', numeric: false, disablePadding: false, label: 'Threshold',
          },
          {
            id: 'operator', numeric: false, disablePadding: false, label: 'Operator',
          },

        ];
        data.rows = sortOutAverages(data.rows);
      }
      data.title = 'Monitoring Records';

      if (!clean && includeUser) {
        data.headCells.push({
          id: 'updatedBy', numeric: false, disablePadding: false, fontSize: '9px', label: 'Updated by',
        });
      }
      spliceBlockHeader(includeBlock, data.headCells, 3, clean);
      return data;

    }

    //
    case 'activities': {
      const agronomistActs = getFromGlobalState('agronomistActs');
      let records = planting[recordType] ? planting[recordType] : [];
      if (startDate && endDate) {
        records = records.filter((r) => r.date >= startDate && r.date <= endDate);
      }
      data.rows = records ? records.map((item) => {
        const ob = { ...item };
        if (!clean && includeUser) {
          ob.updatedBy = item.createdByDetail ? `${item.createdByDetail.name} ${item.createdByDetail.surname} ${new Date(parseInt(item.createdAt)).toLocaleString()}` : '';
        }
        const agronomistAct = agronomistActs && agronomistActs.find((p) => p.sk === item.activity);
        if (agronomistAct) {
          ob.name = agronomistAct.name;
          ob.description = agronomistAct.description;
          insertBlockDetail(includeBlock, ob, data.blockNumber, data.plantingLabel, data.plantingName, data.plantingSK, data.blockSK, data.cropName, data.varietyName);
        }
        const operator = getObjectFromKey('operators', item.operator, getFromGlobalState);
        ob.operator = operator ? `${operator.name} ${operator.surname}` : '';
        return ob;
      }) : [];
      if (!noSort) {
        data.rows = data.rows && data.rows.sort((a, b) => new Date(b.date) - new Date(a.date));
      }
      data.mostRecent = data.rows && data.rows.slice(0, RECENTS_LEN);
      if (clean) {
        data.headCells = [
          {
            id: 'date', opacity: 1, label: 'Date',
          },
          {
            id: 'name', opacity: 1, label: 'Activity',
          },
        ];
      } else {
        data.headCells = [
          {
            id: 'date', numeric: false, disablePadding: false, label: 'Date', minWidth: '80px',
          },
          {
            id: 'name', numeric: false, disablePadding: false, label: 'Activity',
          },
          {
            id: 'description', numeric: false, disablePadding: false, label: 'Description',
          },
          {
            id: 'operator', numeric: false, disablePadding: false, label: 'Operator',
          },

        ];
      }
      data.title = 'Agronomic Activity Records';
      if (!clean && includeUser) {
        data.headCells.push({
          id: 'updatedBy', numeric: false, disablePadding: false, fontSize: '9px', label: 'Updated by',
        });
      }
      spliceBlockHeader(includeBlock, data.headCells, 0, clean);
      return data;
    }
    case 'sprayings': {
      const combinations = getFromGlobalState('combinations').filter((x)=>x.combinationType === "Plant Protection");
      const chemicals = getFromGlobalState('chemicals').concat(combinations);
      const pests = getFromGlobalState('monitorings');
      const reasons = generateReasonLis(pests);
      const chemequips = getFromGlobalState('chemequips');
      let records = planting[recordType] ? planting[recordType] : [];
      if (startDate && endDate) {
        records = records.filter((r) => r.date >= startDate && r.date <= endDate);
      }
      data.rows = records ? records.map((item) => {
        const ob = { ...item };
        ob.startTime = ob.time;
        ob.endTime = ob.endTime ? ob.endTime : 'Busy';
        if (!clean && includeUser) {
          ob.updatedBy = item.createdByDetail ? `${item.createdByDetail.name} ${item.createdByDetail.surname} ${new Date(parseInt(item.createdAt)).toLocaleString()}` : '';
        }
        ob.productName = getNameFromKey2(item.product, chemicals);
        ob.phiLapse = ob.endDate ? item.phiLapse : 'Busy';
        insertBlockDetail(includeBlock, ob, data.blockNumber, data.plantingLabel, data.plantingName, data.plantingSK, data.blockSK, data.cropName, data.varietyName);
        ob.totalVolume = ob.endDate ? `${parseFloat(item.volume).toLocaleString(locale)} ℓ` : 'Busy';
        ob.weather = ob.endDate ? item.weather : 'Busy';
        const chemical = chemicals.find((p) => p.sk === item.product);
        if (chemical) {
          ob.tradeName = chemical.name;
          ob.advisor = chemical?.advisor;
          ob.activeIngredient = chemical.aIngredient;
          ob.unit = chemical.unit;
          const operator = getObjectFromKey('operators', item.operator, getFromGlobalState);
          ob.operator = operator ? `${operator.name} ${operator.surname}` : '';
          const target = item && item.reasons && item.reasons.find((r) => r.applied === true);
          if (target) {
            ob.phi = target.phi;
            ob.dosageUnit = target.dosageUnit;
            ob.dosage = target.dosage ? target.dosage.toLocaleString(locale) : '';
          }
        }

        ob.reasonsString = '';

        if (item.reasons) {
          const reasonLength = item.reasons.length;
          item.reasons.map((reason, i) => {
            const pestName = getNameFromKey2(reason.target, reasons);
            ob.reasonsString = ob.reasonsString.concat(pestName);
            if (i < reasonLength - 1) {
              ob.reasonsString = ob.reasonsString.concat(', ');
            }
            return ob.reasonsString;
          });
        }
        ob.chemEquipsString = '';

        if (item.chemrigs) {
          ob.chemEquipsString = getNameFromKey2(item.chemrigs, chemequips);
        }

        return ob;
      }) : [];
      if (!noSort) {
        data.rows = data.rows && data.rows.sort((a, b) => new Date(b.date) - new Date(a.date));
      }
      data.mostRecent = data.rows && data.rows.slice(0, RECENTS_LEN);
      if (clean) {
        data.headCells = [
          {
            id: 'date',
            combine: [
              { id: 'date', opacity: 1 },
              { id: 'tradeName', opacity: 0.6 },

            ],
            numeric: false,
            disablePadding: false,
            label: 'Date',
          },

          {
            id: 'totalVolume', combine: [{ id: 'totalVolume', opacity: 1 }], numeric: true, disablePadding: false, label: '( per block)',
          },

        ];
      } else {
        data.headCells = [
          {
            id: 'date', numeric: false, disablePadding: false, label: 'Date',
          },{
            id: 'endTime', numeric: false, disablePadding: false, label: 'End time',
          },
          {
            id: 'reasonsString', numeric: false, disablePadding: false, label: 'Target',
          },
          {
            id: 'tradeName', numeric: false, disablePadding: false, label: 'Trade name',
          },
          {
            id: 'activeIngredient', numeric: false, disablePadding: false, label: 'Active Ingredient', minWidth: '150px',
          },
          {
            id: 'dosage', numeric: true, disablePadding: false, label: 'Dosage',
          },
          {
            id: 'dosageUnit', numeric: false, disablePadding: false, label: 'Dosage unit',
          },
          {
            id: 'chemEquipsString', numeric: false, disablePadding: false, label: 'Equipment',
          },
          {
            id: 'phi', numeric: false, disablePadding: false, label: 'PHI (days)',
          },
          {
            id: 'phiLapse', numeric: false, disablePadding: false, label: 'Withholding period end',
          },{
            id: 'totalVolume', numeric: true, disablePadding: false, label: 'Total volume (L)',
          },
          {
            id: 'weather', numeric: false, disablePadding: false, label: 'Weather',
          },
          {
            id: 'operator', numeric: false, disablePadding: false, label: 'Operator',
          },
          {
            id: 'advisor', numeric: true, disablePadding: false, label: 'Advisor',
          }
        ];
      }
      data.title = 'Plant Protection Applications';
      if (!clean && includeUser) {
        data.headCells.push({
          id: 'updatedBy', numeric: false, disablePadding: false, fontSize: '9px', label: 'Updated by',
        });
      }
      spliceBlockHeader(includeBlock, data.headCells, 0, clean);
      return data;
    }
    case 'harvestings': {
      const metaData = getFromGlobalState('harvestequips');
      let records = planting[recordType] ? planting[recordType] : [];
      if (startDate && endDate) {
        records = records.filter((r) => r.date >= startDate && r.date <= endDate);
      }
      if (records.length === 0) {
        return data;
      }

      const dtFix = (item, locale) => {
        try {
            return new Date(item.date).toLocaleString(locale);
        } catch (error) {
            console.error("Locale error:", error.message);
            // Fallback to a default locale, e.g., 'en-US'
            return new Date(item.date).toLocaleString('en-US');
        }
      };
    
      data.rows = records ? records.map((item) => {
        const ob = { ...item };
        ob.harvestTotalNoUnit = ob.harvestTotal;
        const dt = dtFix(item.date,locale);
        ob.date = dt.substring(0, dt.indexOf(','));
        ob.harvestTotal = `${ob.harvestTotal} kg`;
        ob.createTime = new Date(ob.createdAt).toLocaleString(locale);
        ob.clientTime = new Date(ob.clientCreateDate).toLocaleString(locale);
        ob.harvestUnits = ob.harvestUnits ? ob.harvestUnits : 0;
        // const operator = item.operator;
        const operator = getObjectFromKey('operators', item.operator, getFromGlobalState);
        ob.operator = operator ? `${operator.name} ${operator.surname}` : '';
        if (!clean && includeUser) {
          ob.updatedBy = item.createdByDetail ? `${item.createdByDetail.name} ${item.createdByDetail.surname} ${new Date(parseInt(item.createdAt)).toLocaleString()}` : '';
        }
        insertBlockDetail(includeBlock, ob, data.blockNumber, data.plantingLabel, data.plantingName, data.plantingSK, data.blockSK, data.cropName, data.varietyName);

        ob.equipName = item.harvestEquip ? (item.totalHarvestOverride ? `${getNameFromKey2(item.harvestEquip, metaData)} (override)` : getNameFromKey2(item.harvestEquip, metaData)) : 'override';
        return ob;
      }) : [];
      // let unit = data.rows[0] ? data.rows[0].productUnit: '';
      // sort rows
      if (!noSort) {
        data.rows = data.rows && data.rows.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      }
      data.mostRecent = data.rows && data.rows.slice(0, RECENTS_LEN);
      const totalUnits = data.rows.reduce((prev, cur) => prev + (cur.harvestUnits ? cur.harvestUnits * 1.0 : 0), 0);
      const totalUnitsLocale = totalUnits.toLocaleString();

      const totalHarvest = data.rows.reduce((prev, cur) => prev + parseFloat(cur.harvestTotalNoUnit), 0);
      const totalHarvestLocale = `${(totalHarvest / 1000).toLocaleString(locale)} t`;
      if (clean) {
        data.headCells = [
          // combine:[{id:'duration',opacity:1,postfix:'hour'}]
          {
            id: 'date',
            combine: [
              { id: 'date', opacity: 1 },
              { id: 'equipName', opacity: 0.6 },
              // {id:'displayValue',opacity:0.6},
              { id: 'harvestUnits', opacity: 0.6, postfix: 'units' },

            ],
            numeric: false,
            disablePadding: false,
            label: 'Date',
          },

          {
            id: 'harvestTotal', combine: [{ id: 'harvestTotal', opacity: 1 }], numeric: true, disablePadding: false, label: 'Total',
          },

        ];
      } else {
        data.headCells = [
          {
            id: 'date', numeric: false, disablePadding: false, label: 'Date', minWidth: '80px',
          },
          {
            id: 'equipName', numeric: false, disablePadding: false, label: 'Equipment',
          },
          {
            id: 'harvestUnits', numeric: true, disablePadding: false, label: 'Units',
          },
          {
            id: 'harvestTotal', numeric: true, disablePadding: false, label: 'Harvested',
          },
          {
            id: 'deliveryNote', numeric: true, disablePadding: false, label: 'Delivery Note',
          },
          {
            id: 'operator', numeric: true, disablePadding: false, label: 'Operator',
          },
          /* {
            id: 'createTime', numeric: false, disablePadding: false, label: 'Updated Server',
          }, */
          {
            id: 'clientTime', numeric: false, disablePadding: false, label: 'Time stamp',
          },

        ];
      }
      data.title = 'Harvest Records';
      if (!clean && includeUser) {
        data.headCells.push({
          id: 'updatedBy', numeric: false, disablePadding: false, fontSize: '9px', label: 'Updated by',
        });
      }
      spliceBlockHeader(includeBlock, data.headCells, 0, clean);

      if (!clean) {
        let totalRow = {};

        if (!includeBlock) {
          totalRow = {
            strong: true, date: 'Total', duration: '', harvestTotalNoUnit: 0, volumePerHour: '', harvestUnits: totalUnitsLocale, harvestTotal: totalHarvestLocale,
          };
        } else {
          totalRow = {
            strong: true, blockNumber: 'Total', duration: '', harvestTotalNoUnit: 0, volumePerHour: '', harvestUnits: totalUnitsLocale, harvestTotal: totalHarvestLocale,
          };
        }
        data.rows.push(totalRow);
        data.rows.push({ blockNumber: ' ', date: ' ', harvestTotalNoUnit: 0 });
      }

      return data;
    }
    case 'packings': {
      const metaData = getFromGlobalState('packequips');
      let records = planting[recordType] ? planting[recordType] : [];
      if (startDate && endDate) {
        records = records.filter((r) => r.date >= startDate && r.date <= endDate);
      }
      data.rows = records ? records.map((item) => {
        const ob = { ...item };
        ob.equipName = getNameFromKey2(item.packEquip, metaData);
        ob.wastepName = getNameFromKey2(item.wasteEquip, metaData);
        ob.packTotal = `${ob.packTotal} kg`;
        if (!clean && includeUser) {
          ob.updatedBy = item.createdByDetail ? `${item.createdByDetail.name} ${item.createdByDetail.surname} ${new Date(parseInt(item.createdAt)).toLocaleString()}` : '';
        }
        ob.wasteTotal = `${ob.wasteTotal} kg`;
        insertBlockDetail(includeBlock,
          ob, data.blockNumber,
          data.plantingLabel,
          data.plantingName,
          data.plantingSK,
          data.blockSK,
          data.cropName,
          data.varietyName);
        const conversion = ((item.packTotal - item.wasteTotal) / item.packTotal) * 100;
        ob.conversionRatio = `${conversion.toFixed(1)} %`;
        return ob;
      }) : [];
      // let unit = data.rows[0] ? data.rows[0].productUnit: '';
      // sort rows
      if (!noSort) {
        data.rows = data.rows && data.rows.sort((a, b) => new Date(b.date) - new Date(a.date));
      }
      data.mostRecent = data.rows && data.rows.slice(0, RECENTS_LEN);
      if (clean) {
        data.headCells = [
          // combine:[{id:'duration',opacity:1,postfix:'hour'}]
          {
            id: 'date',
            combine: [
              { id: 'date', opacity: 1 },
              { id: 'equipName', opacity: 0.6 },
              // {id:'displayValue',opacity:0.6},
              { id: 'packUnits', opacity: 0.6, postfix: 'units' },

            ],
            numeric: false,
            disablePadding: false,
            label: 'Date',
          },

          {
            id: 'packTotal', combine: [{ id: 'packTotal', opacity: 1 }], numeric: true, disablePadding: false, label: 'Total',
          },

        ];
      } else {
        data.headCells = [
          {
            id: 'date', numeric: false, disablePadding: false, label: 'Date', minWidth: '80px',
          },
          {
            id: 'wastepName', numeric: false, disablePadding: false, label: 'Waste Equipment',
          },
          {
            id: 'wasteUnits', numeric: true, disablePadding: false, label: 'Units',
          },
          {
            id: 'wasteTotal', numeric: true, disablePadding: false, label: 'Waste Total',
          },
          {
            id: 'equipName', numeric: false, disablePadding: false, label: 'Equipment',
          },
          {
            id: 'packUnits', numeric: true, disablePadding: false, label: 'Units',
          },
          {
            id: 'packTotal', numeric: true, disablePadding: false, label: 'Pack Total',
          },
          {
            id: 'conversionRatio', numeric: true, disablePadding: false, label: 'Conversion Ratio',
          },
          {
            id: 'consignment', numeric: true, disablePadding: false, label: 'Consignment Note',
          },
          {
            id: 'deliveryNote', numeric: true, disablePadding: false, label: 'Delivery Note',
          },
          {
            id: 'destination', numeric: true, disablePadding: false, label: 'Destination',
          },

        ];
      }
      data.title = 'Packing Records';
      if (!clean && includeUser) {
        data.headCells.push({
          id: 'updatedBy', numeric: false, disablePadding: false, fontSize: '9px', label: 'Updated by',
        });
      }
      spliceBlockHeader(includeBlock, data.headCells, 0, clean);
      return data;
    }
    default: {
      return data;
    }
  }
}
