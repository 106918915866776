import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Load from '../../components/Load';
import Tabs from '../../components/Tabs';
import InventoryForm from './inventoryForm';
import SearchAppBar from '../../components/SearchBar';
import { Grid } from '@material-ui/core';
import ReportInventoryCSV from '../ReportInventoryCVS';
import { getDataByRecordType } from '../../libs/analytics';
import settings from '../../libs/settings';
import DateRangeDialog from '../../components/DateRangeDialog';

const { RECENTS_LEN } = settings.constants;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    backgroundColor: 'inherit',
  },
  heading: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  section: {
    margin: theme.spacing(2, 4),
  },
});

class Inventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0,
      expandedProduct: null,
      selectedProduct: null,
      inventoryItems: null, // this.props.initialState,
      inventoryRequests: [],
      selectedInventoryItem: null,
      selectedChemicalCrop: null,
      expandedContent: null,
      modalObject: null,
      isNew: false,
      inventoryItemDialogOpen: false,
      chemicalCropDialogOpen: false,
      inventoryRequestDialogOpen: false,
      errors: {},
      openModal: '',
      showAllDialogOpen: false,
      showReportDialogOpen: false,
      clean: true,
      pdf: false,
      startDate: undefined,
      endDate: undefined,
      csv:false,
      applications:[]
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    const inventoryItems = this.props.getFromGlobalState('inv_chemical');
    
  
  }
  handleTabChange = (event,newValue) => {
    let currentTab = this.state.selectedTab;
    if (newValue !== currentTab) {
      this.setState({selectedTab: newValue})
    }
  }
  

  createData(sk,name, calories, fat, carbs, protein) {
    return {
        sk, name, calories, fat, carbs, protein 
    };
  }

  blockReportPDF () {
  
    this.setState({ pdf: true });
  } 


  blockReportCSV () {
  
    this.setState({ csv: true });
  } 

  onDownloaded = () => {
    this.setState({ pdf: false, csv: true, });
  }


  sortProductByType = (data,sk,type)=>{

    const products = data && data;
     return products.map((x)=>({
      ...x,
      inout:'out',
      amount:type === "sprayings" ? x.volume : parseFloat(x.total),
      comment:'Application on '+ x.cropName,
      reason:'Application'
 }))
 }

//  heading='Plant Protection' 
//       inventoryName='inv_chemical'
//       getFromGlobalState={getFromGlobalState}
//       setGlobalState={setGlobalState}
//       initialState={this.props.getFromGlobalState('inv_chemical')}
//       metaData={{
//         products: this.props.getFromGlobalState('chemicals'),
//         blocks: this.props.getFromGlobalState('blocks'),
//         crops: this.props.getFromGlobalState('crops')
//       }}
//       activityName={'sprayings'}

  
  getProductItems = (product, startDate, endDate) => {
    const { inventoryItems, inventoryRequests } = this.state;
    let items = inventoryItems;
    const SprayingData = getDataByRecordType(this.state.activityType, this.props.getFromGlobalState('blocks'), this.props.getFromGlobalState, true, true, 100, undefined, undefined, true, true);
    const sortedProducts = this.sortProductByType(SprayingData.rows, product?.sk,this.props.activityName);
    const minStock = parseFloat(product?.minStock);
    

    let rows = sortedProducts.concat(items).map((item) => {
      const ob = { ...item };
      if (ob.inout === 'out') {
        ob.amount = -1 * ob.amount;
      }
      ob.actionType = 'Entry';

      return ob;
    });
    const cumulativeSum = ((sum, item) => (item) => sum += parseFloat(item.amount))(0);
    
    const balances = rows.map(cumulativeSum);
    

    rows = rows.map((item, i) => {
      const newItem = { ...item };
      newItem.balance = balances[i];
      return newItem;
    });
    const total = rows.reduce((accumulator, row) => accumulator + parseFloat(row.amount), 0);

    const status = (total > 1.1 * minStock ? 'green' : (total > minStock && total <= 1.1 * minStock ? 'amber' : 'red'));

    let requestArray = [];
 
    rows = rows && rows.sort((a, b) => new Date(b.date) - new Date(a.date));
    const count = rows.length;
    const mostRecent = rows.slice(0, RECENTS_LEN);
    const unit  = product?.unit;
    let headCells = [];
    let headCellsClean = [];

    headCellsClean = [
      {
        id: 'date', combine: [{ id: 'date', opacity: 1 }, { id: 'reason', opacity: 0.6 }], numeric: false, disablePadding: false, label: 'Date',
      },
      {
        id: 'amount', combine: [{ id: 'amount', opacity: 1 }], numeric: true, disablePadding: false, label: `(${unit})`,
      },
    ];


    headCells = [
      {
        id: 'date', numeric: false, disablePadding: false, label: 'Date', minWidth: '80px',
      },
      {
        id: 'blockNumber', numeric: false, disablePadding: false, label: 'Block', minWidth: '80px',
      },
      {
        id: 'plantingName', numeric: false, disablePadding: false, label: 'Planting', minWidth: '80px',
      },
      {
        id: 'reason', numeric: false, disablePadding: false, label: 'Reason', minWidth: '70px',
      },
      {
        id: 'comment', numeric: false, disablePadding: false, label: 'Comment', minWidth: '70px',
      },
      {
        id: 'amount', numeric: true, disablePadding: false, label: `Total (${unit})`,
      },
      {
        id: 'balance', numeric: true, disablePadding: false, label: `Balance (${unit})`,
      },

    ];
    return ({
      count, mostRecent, rows: rows, headCells, headCellsClean, total, unit, minStock, status: { status, message: '' },
    });
  }
  
  render() {
    const {classes,  getFromGlobalState, setGlobalState} = this.props;
    const {
      inventoryItemDialogOpen, isNew, selectedInventoryItem, selectedProduct, inventoryRequestDialogOpen,
      selectedInventory, showAllDialogOpen, expandedProduct, pdf, csv, clean, selectedinventoryItemSK, showReportDialogOpen, startDate, endDate,
    } = this.state;

    const productItems = this.getProductItems();
    const farm = getFromGlobalState('farm')[0];
    const userDetail = getFromGlobalState('userDetail');

    function sortByDate(array, dateProperty) {
      return array.slice().sort((a, b) => {
          const dateA = new Date(a[dateProperty]);
          const dateB = new Date(b[dateProperty]);
          return dateA - dateB;
      });
  }

    return (
      <Load isloading={this.state.isloading}>
        <Paper elevation={0} className={classes.paper}>
        <Grid className={classes.searchBar} key='search' style={{padding:"3px",marginBottom:'3px'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper > <SearchAppBar 
              pdfRequested={pdf} 
              csvRequested={csv}
              onBlockReportPDF={this.blockReportPDF.bind(this)}
              onBlockReportCSV={this.blockReportCSV.bind(this)}
              // activeSite={activeSite}
              getFromGlobalState={getFromGlobalState}
              // isMobile={isMobile}
              // onSwitchFarm={this.handleSwitchFarm}
              reportCSV={<ReportInventoryCSV onDownloaded={this.onDownloaded} data={{headCells:productItems.headCells,title:'Inventory Records',rows:startDate && endDate ? sortByDate(productItems.rows, "date").filter((r) => {return r.date >= startDate && r.date <= endDate; }) : sortByDate(productItems.rows, "date")}} color="secondary" className={classes.pdfButton} startDate={startDate} endDate={endDate} farm={farm}  />}
              // reportPDF={<ReportInventoryPDF onDownloaded={this.onDownloaded} inventoryName={inventoryName} product={product} color="secondary" className={classes.pdfButton} farm={farm} headCells={productItems.headCells} startDate={startDate} endDate={endDate} 
              // rows={startDate && endDate ? sortByDate(productItems.rows, "date").filter((r) => {return r.date >= startDate && r.date <= endDate; }) : sortByDate(productItems.rows, "date")}
              // getFromGlobalState={getFromGlobalState}/>}
              /></Paper>
        </Grid>
        {this.state.dateRangeDialogOpen
        && (
          <DateRangeDialog
          open={this.state.dateRangeDialogOpen}
          loading={this.state.reportLoading}
          title="Report Date Range"
          handleConfirm={this.state.reportType === 'pdf'? this.handleGeneratePDF :  this.handleGenerateCSV}
          handleCancel={this.state.reportType === 'csv' ? this.handleCancelGeneratePDF : this.handleCancelGenerateCSV}
          startDate={this.state.reportStartDate}
          endDate={this.state.reportEndDate}
          handleSetStartDate={this.setReportStartDate}
          handleSetEndDate={this.setReportEndDate}
        />
        )}
    <Tabs value={this.state.selectedTab} handleChange={this.handleTabChange}> 
      <InventoryForm 
      heading='Plant Protection' 
      inventoryName='inv_chemical'
      getFromGlobalState={getFromGlobalState}
      setGlobalState={setGlobalState}
      initialState={this.props.getFromGlobalState('inv_chemical')}
      metaData={{
        products: this.props.getFromGlobalState('chemicals'),
        blocks: this.props.getFromGlobalState('blocks'),
        crops: this.props.getFromGlobalState('crops')
      }}
      activityName={'sprayings'}
      />
      <InventoryForm 
      heading="Fertiliser" 
      inventoryName='inv_fertiliser'
      getFromGlobalState={getFromGlobalState}
      setGlobalState={setGlobalState}
      initialState={this.props.getFromGlobalState('inv_fertiliser')}
      metaData={{
        products: this.props.getFromGlobalState('fertilisers'),
        blocks: this.props.getFromGlobalState('blocks'),
        crops: this.props.getFromGlobalState('crops')
      }}
      activityName={'fertilisings'}
      />
  </Tabs>
       </Paper>
       </Load>
    );
  }
}
export default withStyles(styles)(Inventory);
