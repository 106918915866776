import React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { fade, makeStyles } from '@material-ui/core/styles';
import PDFIcon from '@material-ui/icons/PictureAsPdfOutlined';
import CSVIcon from '@material-ui/icons/GetAppOutlined';
import RefreshIcon from '@material-ui/icons/Refresh';
import Select from '@material-ui/core/Select';
import Can from '../Can';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    // marginRight: theme.spacing(2),

  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    // width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  select: {
    '&:before': {
    },
    '&:after': {
    },
  },
  icon: {
  },
  input: {
  },
  inputRoot: {
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
}));

export default function SearchAppBar(props) {
  const {
    onRefresh, onSwitchFarm, isMobile, onBlockReportPDF, pdfRequested, reportPDF, reportCSV, onBlockReportCSV, csvRequested, siteList, activeSite, getFromGlobalState,
  } = props;

  const classes = useStyles();
  const userDetail = getFromGlobalState('userDetail');

  return (
    <div className={classes.root}>
      <div position="static">
        <Toolbar style={{ backgroundColor: 'white', borderRadius: '4px' }}>
          <div className={classes.search}>
            <Can
              role={userDetail && userDetail.rights && userDetail.rights.viewDashboard ? 'viewDashboard' : 'noAccess'}
              perform="viewReports"
              isAdministrator={userDetail && userDetail.rights && userDetail.rights.administrator}
              yes={() => (
                <div style={{ display: 'flex', float: 'left' }}>
                  {!csvRequested
             && (
             <Tooltip title="Generate CSV report">
               <IconButton edge="start" className={classes.menuButton} color="primary" onClick={onBlockReportCSV} aria-label="close">
                 <CSVIcon />
               </IconButton>
             </Tooltip>
             )}
                  {csvRequested
               && reportCSV}
                  {!pdfRequested
             && (
             <Tooltip title="Generate PDF report">
               <IconButton edge="start" className={classes.menuButton} color="primary" onClick={onBlockReportPDF} aria-label="close">
                 <PDFIcon />
               </IconButton>
             </Tooltip>
             )}
                  {pdfRequested
               && reportPDF}
                </div>
              )}
            />

          </div>
          {isMobile && (
          <Select
            native
            className={classes.select}
            required
            value={activeSite}
            onChange={onSwitchFarm}
            inputProps={{
              name: 'activeSite',
              id: 'activeSite',
              classes: {
                icon: classes.icon,
                root: classes.input,
              },
            }}
          >
            {siteList
              && siteList.map(
                (site) => (
                  <option key={site.sk} value={site.tenantId}>
                    {site.shortName === "Main site" ? site.shortName2 : site.shortName}
                    {' '}
                    -
                    {site.entityType ? site.entityType.charAt(0).toUpperCase() : 'F' }

                  </option>
                ),
              )}

          </Select>
          )}
          {onRefresh && <div style={{ marginLeft: 'auto', float: 'right' }}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              aria-label="refreshr"
              color="secondary"
              onClick={onRefresh}
            >
              <RefreshIcon />
            </IconButton>
          </div>}
        </Toolbar>
      </div>
    </div>
  );
}
SearchAppBar.propTypes = {
  classes: PropTypes.object,
  onRefresh: PropTypes.func.isRequired,
  onBlockReportPDF: PropTypes.func.isRequired,
  pdfRequested: PropTypes.bool.isRequired,
  reportPDF: PropTypes.any,
  reportCSV: PropTypes.any,
  csvRequested: PropTypes.bool.isRequired,
  onBlockReportCSV: PropTypes.func.isRequired,
  siteList: PropTypes.array.isRequired,
  activeSite: PropTypes.string.isRequired,
  onSwitchFarm: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,

};
