/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import AppBarMUI from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import OfflineIcon from '@material-ui/icons/CloudOffOutlined';
import OnlineIcon from '@material-ui/icons/CloudDoneOutlined';
import Logo from '../../images/logo.png';
import AutoAvatar from '../AutoAvatar';
import metadata from '../../metadata.json';
import Can from '../Can';

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    top: 0,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  title: {
    // flexGrow: 1,
    minWidth: '90px',
    marginLeft: '30px',
  },
  location: {
    flexGrow: 1,
    paddingLeft: '8px',
  },
  menuButton: {
    marginRight: 20,

  },
  formControl: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    borderRadius: '5px',
    minWidth: 120,
    boxShadow: 'none',
  },
  select: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
  },
  selected: {
    color: theme.palette.secondary.main,
  },
  icon: {
    fill: 'white',
    color: 'inherit',
  },
  input: {
    color: 'white',
  },
  selectP: {
    paddingLeft: '8px',
  },
  item: {
    color: 'inherit',
  },
  menuPaper: {
    maxHeight: 250,
    paddingLeft: '8px',
    paddingRight: '8px',
    cursor: 'pointer',
    overflowX: 'scroll',
  },
});

class AppBar extends React.Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this); // i think you are missing this
  }

  handleChange = async (event) => {
    const { getFromGlobalState, refreshState, sync } = this.props;
    const activeSite = event.target.value;
    const activeSiteCurr = getFromGlobalState('activeSite');
    if (event.target.value !== activeSiteCurr) {
      await refreshState(activeSite);
      await sync(activeSite);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  pathToHeader(path) {
    switch (path) {
      case '/': {
        return 'Blocks';
      }
      default: {
        const header = path.substring(1, path.lenth);
        const headerCapitalised = header.charAt(0).toUpperCase() + header.slice(1);
        return headerCapitalised;
      }
    }
  }

  goBack() {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      classes,
      isAuthenticated,
      handleLogout,
      getFromGlobalState,
      online,
      handleDrawerToggle,
      location: { pathname },
      industryView,
    } = this.props;
    let siteList = getFromGlobalState('siteList');
    siteList = siteList.sort((a, b) => (`${a.shortName}`).localeCompare(b.shortName, undefined, { numeric: true }));
    siteList = siteList.filter((s) => !s.isDisabled || s.isDisabled !== true);
    const activeSite = getFromGlobalState('activeSite');
    const userDetail = getFromGlobalState('userDetail');
    const farm = getFromGlobalState('farm');
    const isEntity = !!(farm && (farm[0] && farm[0].entityType && farm[0].entityType === 'entity'));
    const userKey = userDetail ? userDetail.sk : 'none';
    const userName = userDetail ? `${userDetail.name} ${userDetail.surname}` : 'X X';
 
    console.log("siteList",siteList);
 
    return (
      <AppBarMUI position="absolute" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            // disabled={isEntity}
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {/* } <IconButton
            color="inherit"
            aria-label="Back"
            disabled = {(history.location.pathname === '/' )}
            onClick={this.goBack}
            className={classes.menuButton}
          >
            <BackIcon />
        </IconButton>  */}
          <img style={{ height: '50px', marginLeft: '30px' }} src={Logo} alt="Logo" />
          <Typography key="1a" variant="overline" color="inherit" noWrap className={classes.title}>
            {/* }{`Land Diary ${farmId}`} { */}
            {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`}
          </Typography>

          <Typography key="2a" variant="h6" color="inherit" noWrap className={classes.location}>
            {this.pathToHeader(pathname)}
          </Typography>
          {!industryView && (
          <>
            <InputLabel style={{ color: 'white' }}>Available farms</InputLabel>
            <FormControl className={classes.formControl}>
              <Select
              // native
                disableUnderline
                key="3a"
             // className={classes.select}
                required
              // style={{ paddingLeft: '8px' }}
                value={activeSite}
                onChange={this.handleChange}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                inputProps={{
                  name: 'activeSite',
                  id: 'activeSite',
                  classes: {
                    //  icon: classes.icon,
                    nativeInput: classes.selectP,
                    select: classes.selectP,
                  },
                }}
              >
                {siteList
              && siteList.sort().map(
                (site) => (
                  <option key={site.sk} value={site.tenantId}>
                    {site.shortName === "Main site" ? site.shortName2 : site.shortName}
                    {' '}
                    -
                    {site.entityType ? site.entityType.charAt(0).toUpperCase() : 'F' }
                  </option>
                ),
              )}

              </Select>
            </FormControl>
          </>
          )}

          {online ? <OnlineIcon key="4a" style={{ color: 'green', paddingRight: '15px' }} /> : <OfflineIcon style={{ paddingRight: '15px' }} color="error" />}
          {isAuthenticated
          && (
          <>
            <Tooltip key="5a" title={`${userName} click to logout`}>
              <Button color="inherit" onClick={handleLogout}>

                <AutoAvatar key="6a" userKey={userKey} name={userName} />
              </Button>
            </Tooltip>
            {!industryView && (
              <Can
                key="7a"
                role={userDetail && userDetail.rights && userDetail.rights.changeSettings ? 'changeSettings' : 'noAccess'}
                perform="openSettingsPage"
                yes={() => (
                  <Tooltip key="5b" title="Access Settings">
                    <IconButton
                // classes={{ selected: classes.selected }}
                // color='white'
                      style={{ color: 'white' }}
                // disableGutters
                      disablegutters
                      component={Link}
                      to="/settings"
                      selected={pathname === '/settings'}
                    >

                      <SettingsIcon color="white" />

                    </IconButton>
                  </Tooltip>
                )}
              />

            )}
          </>
          )}

          {!isAuthenticated
          && (
          <>
            <MenuItem disabled key="7a" component={Link} to="/signup">
              Sign Up
            </MenuItem>
            <MenuItem key="8a" component={Link} to="/login">
              Login
            </MenuItem>
          </>
          )}
        </Toolbar>
      </AppBarMUI>
    );
  }
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  refreshState: PropTypes.func.isRequired,
  sync: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
  industryView: PropTypes.bool.isRequired,
  // reload: PropTypes.func.isRequired,
  online: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.any.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
};

export default withStyles(styles, { withTheme: true })(AppBar);
