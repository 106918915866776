import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import {green } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import { getNameFromKey, getNameFromKey2 } from '../../libs/genLib';
import Status from '../Status';
import Report from '../../components/Report';
import ShowAllD from '../../containers/ShowAllD';
import ReportPDF from '../../containers/ReportPDF';
import ReportCSV from '../../containers/ReportCSV';



const useStyles = makeStyles(theme => ({
  actions: {
    // minWidth: 275,
    //paddingTop:'3px',
    //paddingBottom:'3px',
   // marginRight: '15px',
   // padding: '3px',

   },
   badgeChild: {
    padding: theme.spacing(0, 0.5),
    margin: '1px',
    //paddingLeft: '0px',
   },
   badge: {
    
    //padding: theme.spacing(0, 2),
    
    color: '#2196f3', // todo link to primary
    //borderStyle: 'solid',
    //borderWidth: 'thin',
    paddingBottom:'1px',
  },
  padding: {
    padding: theme.spacing(0, 2),
  },
  statusBar :{
    display: 'flex',
   flexDirection: 'row',
  },
  expand: {
    transform: 'rotate(0deg)',
    padding: '4px',
    marginLeft: 'auto',
    float: 'left',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    add: { 
     //position: 'fixed', 
    left: '50%',   
    marginLeft: '-24px',
    marginBottom:'10px',
      //margin: 0,
     // float: 'right',
     // marginRight: '12px',
      
    },
    buttonLink: {
      //margin: theme.spacing(1),
      //marginRight: 'auto',
      //paddingLeft: '1px',
      
    },
    cardExpand: {
      padding: '2px',
  
    },
    pdfButton: {
      float:'right',
      marginLeft:'auto',
      color: theme.palette.grey[500],
    },
    paper: {
      marginLeft: '4px',
      //marginTop: '10px',
    },
    heading: {
      marginTop: '4px',
      cursor: 'pointer',
      //marginTop: '10px',
    },
    buttonRight: {
      margin: theme.spacing(1),
      float:'right',
      marginLeft:'auto'
    },
    buttonLeft: {
      margin: theme.spacing(1),
      float:'left',
      marginRight:'auto'
    },
    noneAvatar: {
      margin: 10,
      //color: '#fff',
      backgroundColor: '#fff',
      width: 7,
      height: 7,
      //boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    },
    greenAvatar: {
      margin: 10,
      color: '#fff',
      backgroundColor: green[500],
      width: 7,
      height: 7,
      //boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    },
    redAvatar: {
      margin: 10,
      color: '#fff',
      backgroundColor: theme.palette.error.dark,
      width: 7,
      height: 7,
     // boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    },
    
}));

export default function RecordList(props) {
  const classes = useStyles();
  const [pdf, setPdf] = React.useState(false);
  const [csv, setCSV] = React.useState(false);
  const [reportStartDate, setReportStartDate] = React.useState(false);
  const [reportEndDate, setReportEndDate] = React.useState(false);
  const [generatingPDF, setGeneratingPDF] = React.useState(false);

  const generatePDF = (startDate, endDate) =>{
    //var email = message.emailId;
   
    //setGeneratingPDF(true);
    setPdf(true);
    setReportStartDate(startDate);
    setReportEndDate(endDate);
    
  }

  const generateCSV = (startDate, endDate) =>{
    setCSV(true);
    setReportStartDate(startDate);
    setReportEndDate(endDate);
  }
  const setPDFDone = () =>{
    //setGeneratingPDF(false);
    
  }

  const setCSVDone = () =>{
    //setGeneratingPDF(false);
    
  }
  const onDownloaded = () => {
    setPdf(false);
  }
  const onDownloadedCSV = () => {
    setCSV(false);
  }
  
  

  const {type, expandedRecord, blockSK, plantingSK, onAddRecord,getFromGlobalState,planting,block,farm,onClickViewTableItem,showAll, setCloseShowAll, title, noPagination } = props;
  const expanded = (expandedRecord.plantingSK===plantingSK && expandedRecord.recordType===type);
  let crops = getFromGlobalState('crops');
 //const newTitle = `${getNameFromKey2(planting.crop, metaData.crops, 'name')} - ${planting.variety ? getNameFromKey2(planting.variety, crops.find(crop => crop.sk === planting.crop)?.varieties, 'cultivar'): '' } - ${planting.name? planting.name : ''} `;
 //const blee = `Block ${block.number } ${getNameFromKey2(planting.crop, crops, 'name')} ${planting.variety ? getNameFromKey2(planting.variety, crops.find(crop => crop.sk === planting.crop)?.varieties, 'cultivar') : '' }`;
  const subTitle = `Block ${block.number } ${getNameFromKey2(planting.crop, crops, 'name')} - ${planting.variety ? getNameFromKey2(planting.variety, crops.find(crop => crop.sk === planting.crop)?.varieties, 'cultivar') : '' } - ${planting.name? planting.name : ''}`;
  const getHeader = (record, getFromGlobalState,displayFields) => {
  switch(record.type) { 
    case 'sprayings': { 
      let status='none';
      const name = getNameFromKey('chemicals',record.product,getFromGlobalState)
      let header = `${record.date} | ${name}`;
      if (record.phiLapse) {
      let phiDate = new Date(record.phiLapse.substring(0,10));
      let today = new Date();
       status = (phiDate > today ? 'red':'none'); 
      }
      else {
        status='red';
      }
      return (<>{header}<Status status={record.status} hideIfGreen={true} size='small'/> </>);
       
    } 
    case 'fertilisings': {
      const name = getNameFromKey('fertilisers',record.product,getFromGlobalState)
      let header = `${record.date} | ${name}`;
      return (<>{header} </>);

    }
    case 'pestings': {
      const name = getNameFromKey('pests',record.pest,getFromGlobalState)
      let header = `${record.date} | ${name}`;
      return (<>{header} </>);

    }
    default: { 
      let header = `${record[displayFields[0]]} | ${record[displayFields[1]]}`
      return  (header); 
    
    } 
  }

};

//todo: this needs work
const getChildStatus = (records,type) => {
  if (!records) {
    return 'none';
  }
  switch(type) { 
    case 'sprayings': { 
      let today = new Date();
      for (let spraying of records) {
        if (spraying.phiLapse !== undefined)
        {
          const lapseDate = new Date(spraying.phiLapse.substring(0,10));//todo: fix this, need time
          if (lapseDate > today)
          {
            return 'red';
          }

        }
        else {
          return 'red';
        }

      }
      return 'none';
    }
default: { 
  return 'none';

} 
  }

}

  return (
  
    
    < >
    {showAll && expanded && <ShowAllD
            open={showAll}
            setClose={setCloseShowAll}
            isNew={false}
            onGeneratePDF={generatePDF.bind(this)}
            pdfRequested={pdf}
            csvRequested={csv}
            onGenerateCSV={generateCSV.bind(this)}
            getFromGlobalState={getFromGlobalState}
           
            reportPDF={<ReportPDF startDate={reportStartDate} endDate={reportEndDate} color="secondary" className={classes.pdfButton} setPDFDone={setPDFDone} onDownloaded={onDownloaded} planting={planting} block={block} farm={farm} recordType={type} getFromGlobalState={getFromGlobalState}></ReportPDF>}
            reportCSV={<ReportCSV startDate={reportStartDate} endDate={reportEndDate} color="secondary" className={classes.pdfButton} setCSVDone={setCSVDone} onDownloaded={onDownloadedCSV} planting={planting} block={block} farm={farm} recordType={type} getFromGlobalState={getFromGlobalState}></ReportCSV>}
            title={title}
            subTitle={subTitle}
            >
            <Report 
              displayToolbar 
              dense
              showAll
              noPagination={noPagination}
              recordType={type}
              planting={planting}
              block={block}
              farm={farm}
              type={type}
              handleSelect={onClickViewTableItem.bind(this)}
              getFromGlobalState={getFromGlobalState}
              handleAddItem={onAddRecord.bind(this,blockSK, plantingSK, type)}
              clean
              />
            </ShowAllD>
      }
    <Paper elevation={0} className={classes.paper}>
          <br/>
          </Paper> 
       
          </>
    
    

  
  );
}
RecordList.propTypes = {
  onViewRecord: PropTypes.func.isRequired,
  onAddRecord: PropTypes.func.isRequired,
  onViewReport: PropTypes.func.isRequired,
  onViewAll:PropTypes.func,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleExpandClick: PropTypes.func.isRequired,
  expandedRecord: PropTypes.object.isRequired,
  blockSK: PropTypes.string.isRequired,
  plantingSK: PropTypes.string.isRequired,
  records: PropTypes.array,
  displayFields: PropTypes.array.isRequired,
  getDisplayHeader: PropTypes.func.isRequired,
  getFromGlobalState: PropTypes.func.isRequired,
  planting: PropTypes.array.isRequired,
  block: PropTypes.array,
  recordType:PropTypes.string,
  onClickViewTableItem: PropTypes.func,
  showAll:PropTypes.bool,
  setCloseShowAll:PropTypes.func,
  farm:PropTypes.any,
  noPagination:PropTypes.bool,
};